// PageDetails.js
import React from 'react';
import pages from '../data/pages.json';
import Page from './updateMetaTags';
import LatestProfiles from '../contents/LatestProfiles';
import './PageDetails.css'; // Make sure to create this CSS file

const LatestProfilesSection = ({ title, sx }) => (
  <div className="latest-profiles-section">
    <LatestProfiles sx={sx} />
  </div>
);

const PageDetails = ({ pageName, children }) => {
  const pageData = pages[pageName] || {};

  return (
    <div>
      {/* Update Meta Tags */}
      <Page
        title={pageData.title || 'Matrimony.lk'}
        description={pageData.description || 'Default Description'}
        keywords={pageData.keywords || 'Default Keywords'}
      />

      {/* Main Content with Left and Right Components */}
      <div className="container-fluid">
        <div className="row">
          {/* Left Component */}
          <div className="col-md-3">
            <LatestProfilesSection title="Latest  Profiles" sx={2} />
          </div>

          {/* Central Content */}
          <div className="col-md-6">
            <div id="pageelement">{children}</div>
          </div>

          {/* Right Component */}
          <div className="col-md-3">
            <LatestProfilesSection title="Latest  Profiles" sx={1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageDetails;
