import React from 'react';

const TitleSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedOption = options.find(option => option.value === e.target.value);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange('', '---optional---'); // Reset to default values if no option is found
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const ProfessionSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const BodyTypeSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const ComplexionSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const CreatedbySelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const EducationSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const EthnicitySelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const FoodHabitSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const HightTypeSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const MartialStatusSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const MotherTonqueSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const RasiSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const ReligionSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const SexSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const StarSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const WeightTypeSelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

const CountrySelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '---optional---');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};
const CountryMandatorySelect = ({ value, onChange, options }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedOption = options.find(option => option.value === selectedValue);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.text);
    } else {
      onChange(0, '--choose one--');
    }
  };

  return (
    <select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};

export { ProfessionSelect, TitleSelect, BodyTypeSelect, ComplexionSelect, CreatedbySelect, EducationSelect, EthnicitySelect, FoodHabitSelect, HightTypeSelect, MartialStatusSelect, MotherTonqueSelect, RasiSelect, ReligionSelect, SexSelect, StarSelect, WeightTypeSelect, CountrySelect, CountryMandatorySelect };