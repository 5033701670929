export const API_KEY='cventureslk';
export const NewsID=18;
export const BlogID=18;
export const VlogID=18;
//export const HomePageID=10;
export const ContactUsPage='/reach-us';
export const PrimaryLanguage ='';
export const AdditionalLanguage1 ='en';

//export const API_URL = 'https://localhost:7147/api/'
export const API_CMS_URL = 'https://cvcventurescms.azurewebsites.net/api/';
export const ITEMS_PER_PAGE = 15; // Define how many profiles per page
//export const API_URL = 'https://cvmatrimonywebapi.azurewebsites.net/api/';
//export const API_IDENTITY_URL = 'https://cvidentity20231226163826.azurewebsites.net/api/' ;
//export const API_IDENTITY_URL = 'https://localhost:7200/api/';

//production
export const API_IDENTITY_URL = 'https://cvidentity.azurewebsites.net/api/' ;
export const API_URL = 'https://cvmatrimony.azurewebsites.net/api/';
