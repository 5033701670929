import React, { useState } from 'react';
import axios from 'axios';
//import { API_IDENTITY_URL } from '../lib/constants';
import { API_IDENTITY_URL } from '../common/AppControl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API_IDENTITY_URL}otpresetpassword`, {
        email: email,
      });

      const data = response.data;

      if (data.returnCode === 1) {
        toast.success('Password reset instructions sent to your email');
        setEmail(''); // Clear the email input field
      } else {
        toast.error(data.returnText || 'Failed to send reset instructions');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(
        error.response?.data?.message || 'Failed to send reset instructions'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="reset" style={{ maxWidth: '400px', margin: '0 auto' }}>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
            Email Address:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: loading ? 'not-allowed' : 'pointer',
            width: '100%',
          }}
        >
          {loading ? 'Sending...' : 'Send Link'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ForgotPasswordForm;
