import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contents/profile/AuthContext';
import { fetchProfilesCx, deleteProfileCx } from './Service/ProfileCx';
import './ProfileList.css'; // Import the CSS file
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileList = () => {
  const { token } = useAuth();
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, page]);

  const fetchData = async () => {
    const data = await fetchProfilesCx(searchText, page, pageSize, token);
    setItems(data.items);
    setTotalCount(data.totalCount);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

 // const handleRowClick = (id) => {
 //   navigate(`/Profiles/${id}`);
 // };

  // Custom confirmation toast
  const confirmDelete = (id) => {
    toast(
      <div>
        <p>Are you sure you want to delete this profile?</p>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete(id)}
        >
          Confirm
        </button>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => toast.dismiss()}
        >
          Cancel
        </button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
      }
    );
  };

  const handleDelete = async (id) => {
    try {
      await deleteProfileCx(id, token);
      toast.dismiss(); // Close the confirmation toast
      toast.success("Profile deleted successfully!"); // Success toast
      fetchData(); // Refresh the list
    } catch (error) {
      toast.error("Error occurred while deleting the profile."); // Error toast
    }
  };

  const handleCreate = () => {
    navigate(`/Profiles/new`);
  };

  // Calculate total pages for pagination
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="profile-list-container">
      <h1 className="profile-list-heading">My Profiles</h1>
      <div className="profile-list-actions">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          className="profile-list-search" 
        />
      </div>
      <div>
        <button onClick={handleCreate} className="profile-list-create-btn">
          Create New
        </button>
      </div>
      <table className="profile-list-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Dob</th>
            <th>Views</th>
            <th>Reviewed</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr
              key={item.profileId}
              className={item.sex === 'Male' ? 'male-row' : 'female-row'}
            >
              <td>{item.profileId}</td>
              <td>{item.name}</td>
              <td>{item.age}</td>
              <td>{item.viewCount}</td>
              <td>{item.reviewed}</td>
              <td>{item.active}</td>
              <td>
                <button
                  onClick={() => navigate(`/Profiles/edit/${item.profileId}`)}
                  className="profile-list-edit-btn"
                >
                  Edit
                </button>
                <button
                  onClick={() => confirmDelete(item.profileId)}
                  className="profile-list-delete-btn"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="profile-list-pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`pagination-btn ${page === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Add ToastContainer to display toasts */}
      <ToastContainer />
    </div>
  );
};

export default ProfileList;
