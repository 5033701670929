import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import { useAuth } from  '../contents/profile/AuthContext';
import { API_URL } from '../common/AppControl';
// Function to get headers based on whether the token is needed
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useForm } from 'react-hook-form';
//import { yupResolver } from '@hookform/resolvers/yup';
//import * as Yup from 'yup';


const GetHeaders = (requiresAuth,token) => {
  //const {token} ='';// useAuth(); // Replace with your actual Bearer token
//console.log('GetHeaders'+token);
  return requiresAuth
    ? { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' };
};
const ProfilePage = () => {
  const {token}=useAuth();
  const { profileSlug } = useParams(); // Get the profileSlug from the URL
  const [id, setId] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [permisionData, setPermisionData] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState('');
  //const [selectedProfileCustId, setSelectedProfileCustId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaReady, setRecaptchaReady] = useState(false);

 // Wait for grecaptcha to be available
  useEffect(() => {
    const waitForGrecaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => setRecaptchaReady(true));
      } else {
        // Retry after a short delay if grecaptcha is not available yet
        setTimeout(waitForGrecaptcha, 500);
      }
    };
    waitForGrecaptcha();
  }, []);


  // Extract the profile ID from profileSlug
  useEffect(() => {
    const extractId = () => {
      const idPart = profileSlug.split('_')[0];
      const numericId = parseInt(idPart, 10);
      if (!isNaN(numericId)) {
        setId(numericId);
      } else {
        setError(true);
        setLoading(false);
      }
    };

    extractId();
  }, [profileSlug]);
    // Parse the JSON strings in profileData
    let searchResults = {};
    let basicDetails = {};
    let astroDetails = {};
    let contactDetails = {};
    let imageDisplay = [];
  
  // Update page title based on profile data
  useEffect(() => {
    if (searchResults && searchResults.name) {
      document.title = `${searchResults.name}'s Profile | Matrimony.lk`;
    } else if (error) {
      document.title = 'Profile Not Found | Matrimony.lk';
    } else {
      document.title = 'Loading Profile... | Matrimony.lk';
    }
  }, [searchResults, error]);

  // Fetch the profile data when the ID is set
  useEffect(() => {
    if (id !== null) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${API_URL}pp/${id}`,
          {
            headers: GetHeaders(true,token), // auth required, change it to false if it does not
          });
          if (response && response.data) {
            setProfileData(response.data);
            setPermisionData(response.data.permisionData || []);
          } else {
            setError(true);
          }
        } catch (err) {
          console.error('Error fetching profile data:', err);
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      fetchProfile();
    }
  }, [id]);

  // Loading and error handling remain the same
  if (loading) {
    return (
      <div className="text-center my-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error || !profileData) {
    return (
      <div className="alert alert-danger" role="alert">
        Error loading profile. Please try again later.
      </div>
    );
  }


  try {
    searchResults = JSON.parse(profileData.searchResults);
  } catch (e) {
    console.error('Error parsing searchResults:', e);
    setError(true);
  }

  try {
    basicDetails = JSON.parse(profileData.basicDetails);
  } catch (e) {
    console.error('Error parsing basicDetails:', e);
    setError(true);
  }

  try {
    astroDetails = JSON.parse(profileData.astroDetails);
  } catch (e) {
    console.error('Error parsing astroDetails:', e);
    setError(true);
  }

  try {
    contactDetails = JSON.parse(profileData.contactDetails);
  } catch (e) {
    console.error('Error parsing contactDetails:', e);
    setError(true);
  }

  try {
    imageDisplay = JSON.parse(profileData.imageDisplay);
  } catch (e) {
    console.error('Error parsing imageDisplay:', e);
    imageDisplay = [];
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error processing profile data.
      </div>
    );
  }
  const onSubmit = async () => {
    // Check if reCAPTCHA is ready
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
  
    // Set loading state
    setIsSubmitting(true);
  
    try {
      // Execute reCAPTCHA and get the token
      const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
  
      // Check if the token is valid before making the request
      if (!token1) {
        toast.error('reCAPTCHA failed. Please try again.');
        setIsSubmitting(false);
        return;
      }
  
      // Prepare submission data
      const submissionData = {
        receiverProfileId: id, // The profile being viewed
        requestingProfileId: selectedProfileId, // The selected profile from the dropdown
        receiverCustId: profileData.fkCustId,
        captchaToken: token1, // Pass the reCAPTCHA token
      };
  
      // Make sure selectedProfileId is valid
      if (!selectedProfileId) {
        toast.error('Please select a profile.');
        setIsSubmitting(false);
        return;
      }
  
      // Make the API request
      const response = await axios.post(`${API_URL}pp/requestpermision`, submissionData, {
        headers: GetHeaders(true, token), // Pass headers, including the token
      });
  
      // Handle the API response
      if (response && response.data) {
        const results = response.data;
  
        // Show success or error based on the response
        if (results.success) {
          toast.success(results.message || 'Permission request sent successfully!');
        } else {
          toast.error(results.message || 'Permission request failed.');
        }
      } else {
        toast.warning('Unexpected response from the server.');
      }
    } catch (err) {
      // Catch and display any errors
      console.error('Error submitting permission request:', err);
      toast.error('An error occurred while submitting the request. Please try again.');
    } finally {
      // Reset the loading state
      setIsSubmitting(false);
    }
  };
  
  // Determine gender for styling
  const isFemale = searchResults.sex?.toLowerCase() === 'female';

  // Styles
  const styles = {
    container: {
      backgroundColor: isFemale ? '#ffe6f2' : '#e6f2ff', // Pink for female, blue for male
      padding: '20px',
      borderRadius: '10px',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    image: {
      width: '100%',
      maxWidth: '300px',
      borderRadius: '10px',
    },
    sectionTitle: {
      color: '#333',
      borderBottom: '1px solid #ccc',
      paddingBottom: '5px',
      marginBottom: '15px',
      marginTop: '30px',
    },
    infoRow: {
      display: 'flex',
      marginBottom: '10px',
    },
    infoLabel: {
      width: '150px',
      fontWeight: 'bold',
    },
    infoValue: {},
    politeMessage: {
      fontStyle: 'italic',
      color: '#555',
    },
  };

  // Handle image display
  const defaultImage = isFemale
    ? '/assets/images/default-female.png'
    : '/assets/images/default-male.png';

  let profileImage;
  if (profileData.showphoto) {
    if (profileData.photoavailable){
    profileImage =
      imageDisplay.length > 0
        ? imageDisplay[0].imagename
        : defaultImage;
    } else {
      profileImage = isFemale
      ? '/assets/images/female-no-photo.png'
      : '/assets/images/male-no-photo.png';
    }
  } else 
  if (profileData.photoprotected) {
    profileImage = isFemale
      ? '/assets/images/female-photo-protected.png'
      : '/assets/images/male-photo-protected.png';
  } else
  {
    profileImage = isFemale
      ? '/assets/images/female-no-photo.png'
      : '/assets/images/male-no-photo.png';
  }

  // Render the profile
 return (
    <div style={styles.container}>
      <h2 style={styles.header}>{searchResults.name}'s Profile (ID {searchResults.profileid})</h2>
      <div className="row">
        <div className="col-md-4 text-center">
          <img
            src={profileImage}
            alt={searchResults.name}
            style={styles.image}
          />
                 <p style={styles.politeMessage}>
          {imageDisplay.message}
          </p>
  {permisionData && permisionData.length > 0 && (
    <div>
      <label htmlFor="profileSelect">Select Profile:</label>
      <select
        id="profileSelect"
        value={selectedProfileId}
        onChange={(e) => setSelectedProfileId(e.target.value)}
        className="form-select"
      >
        <option value="">-- Select a Profile --</option>
        {permisionData.map((profile) => (
          <option key={profile.profileId} value={profile.profileId}>
            {profile.name}
          </option>
        ))}
      </select>
      <button
        onClick={onSubmit}
        disabled={isSubmitting || !selectedProfileId}
        className="btn btn-primary mt-2"
      >
        {isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Requesting...
          </>
        ) : (
          'Send Request'
        )}
      </button>
    </div>
  )}
        </div>
        <div className="col-md-8">
          <h3 style={styles.sectionTitle}>Basic Details</h3>
          <div style={styles.infoRow}>
            <div style={styles.infoLabel}>Age:</div>
            <div style={styles.infoValue}>{searchResults.age || 'Not given'}</div>
          </div>
{/* Created By */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Created By:</div>
  <div style={styles.infoValue}>{searchResults.createdby || 'Not given'}</div>
</div>

{/* Marital Status */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Marital Status:</div>
  <div style={styles.infoValue}>{searchResults.martialstatus || 'Not given'}</div>
</div>
{/* Ethnicity */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Ethnicity:</div>
  <div style={styles.infoValue}>{searchResults.ethnicity || 'Not given'}</div>
</div>

{/* Additional Caste */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Caste:</div>
  <div style={styles.infoValue}>{searchResults.additionalcaste || 'Not given'}</div>
</div>

{/* Mother Tongue */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Mother Tongue:</div>
  <div style={styles.infoValue}>{searchResults.mothertonque || 'Not given'}</div>
</div>

{/* Religion */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Religion:</div>
  <div style={styles.infoValue}>{searchResults.religion || 'Not given'}</div>
</div>

 {/* Birth City */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Birth City:</div>
  <div style={styles.infoValue}>{searchResults.birthcity || 'Not given'}</div>
</div>
{/* Birth Country */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Birth Country:</div>
  <div style={styles.infoValue}>{basicDetails.birthcountry || 'Not given'}</div>
</div>




{/* Living City */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Living City:</div>
  <div style={styles.infoValue}>{searchResults.livingcity || 'Not given'}</div>
</div>

{/* Resident Country */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Resident Country:</div>
  <div style={styles.infoValue}>{searchResults.residentcountry || 'Not given'}</div>
</div>


{/* Caste No Bar */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Caste No Bar:</div>
  <div style={styles.infoValue}>{basicDetails.castenobar ? 'Yes' : 'No'}</div>
</div>

{/* Sponsor */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Sponsor:</div>
  <div style={styles.infoValue}>{basicDetails.sponsor ? 'Yes' : 'No'}</div>
</div>

{/* Annual Income */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Annual Income:</div>
  <div style={styles.infoValue}>{basicDetails.annualincome || 'Not given'}</div>
</div>

{/* Occupation */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Occupation:</div>
  <div style={styles.infoValue}>{searchResults.occupation || 'Not given'}</div>
</div>

{/* Additional Occupation */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Additional Occupation:</div>
  <div style={styles.infoValue}>{basicDetails.additionaloccupation || 'Not given'}</div>
</div>

{/* Education */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Education:</div>
  <div style={styles.infoValue}>{searchResults.education || 'Not given'}</div>
</div>

{/* Additional Qualification */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Additional Qualification:</div>
  <div style={styles.infoValue}>{basicDetails.additionalqualification || 'Not given'}</div>
</div>

{/* Not Believe in Horoscope */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Not Believe in Horoscope:</div>
  <div style={styles.infoValue}>{basicDetails.notbeleiveinhor ? 'Yes' : 'No'}</div>
</div>

{/* Height */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Height:</div>
  <div style={styles.infoValue}>{basicDetails.heightText || 'Not given'}</div>
</div>

{/* Weight */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Weight:</div>
  <div style={styles.infoValue}>{basicDetails.weightText || 'Not given'}</div>
</div>


{/* Complexion */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Complexion:</div>
  <div style={styles.infoValue}>{basicDetails.complexion || 'Not given'}</div>
</div>

{/* Food Habits */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Food Habits:</div>
  <div style={styles.infoValue}>{basicDetails.foodhabits || 'Not given'}</div>
</div>

{/* Body Type */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Body Type:</div>
  <div style={styles.infoValue}>{basicDetails.bodytype || 'Not given'}</div>
</div>

{/* Citizen Country */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Citizen Country:</div>
  <div style={styles.infoValue}>{basicDetails.citizencountry || 'Not given'}</div>
</div>

{/* Other Information */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Other Information:</div>
  <div style={styles.infoValue}>{basicDetails.other || 'Not given'}</div>
</div>

{/* Expectations (ex_other) */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Expectations:</div>
  <div style={styles.infoValue}>{basicDetails.exOther || 'Not given'}</div>
</div>

       </div>

      </div>

      {/* Contact Details */}
      <h3 style={styles.sectionTitle}>Contact Details</h3>
      {profileData.showcontact ? (
        <>
{/* Contact Name */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Contact Name:</div>
  <div style={styles.infoValue}>{contactDetails.title} {contactDetails.contactname || 'Not given'}</div>
</div>

{/* Email */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Email:</div>
  <div style={styles.infoValue}>{contactDetails.email || 'Not given'}</div>
</div>

{/* Organization */}
{contactDetails.organization && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Organization:</div>
    <div style={styles.infoValue}>{contactDetails.organization}</div>
  </div>
)}

{/* Address Line 1 */}
{contactDetails.address1 && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Address 1:</div>
    <div style={styles.infoValue}>{contactDetails.address1}</div>
  </div>
)}

{/* Address Line 2 */}
{contactDetails.address2 && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Address 2:</div>
    <div style={styles.infoValue}>{contactDetails.address2}</div>
  </div>
)}

{/* Address Line 3 */}
{contactDetails.address3 && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Address 3:</div>
    <div style={styles.infoValue}>{contactDetails.address3}</div>
  </div>
)}

{/* City */}
{contactDetails.city && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>City:</div>
    <div style={styles.infoValue}>{contactDetails.city}</div>
  </div>
)}

{/* Postcode */}
{contactDetails.postcode && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Postcode:</div>
    <div style={styles.infoValue}>{contactDetails.postcode}</div>
  </div>
)}

{/* Country */}
{contactDetails.country && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Country:</div>
    <div style={styles.infoValue}>{contactDetails.country}</div>
  </div>
)}

{/* Phone 1 */}
{contactDetails.phone1 && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Phone 1:</div>
    <div style={styles.infoValue}>{contactDetails.phone1}</div>
  </div>
)}

{/* Phone 2 */}
{contactDetails.phone2 && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Phone 2:</div>
    <div style={styles.infoValue}>{contactDetails.phone2}</div>
  </div>
)}

{/* Fax */}
{contactDetails.fax && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Fax:</div>
    <div style={styles.infoValue}>{contactDetails.fax}</div>
  </div>
)}

{/* Mobile */}
{contactDetails.mobile && (
  <div style={styles.infoRow}>
    <div style={styles.infoLabel}>Mobile:</div>
    <div style={styles.infoValue}>{contactDetails.mobile}</div>
  </div>
)}
        </>
      ) : (
        <p style={styles.politeMessage}>
          {contactDetails.message}
        </p>
      )}

      {/* Astrological Details */}
      <h3 style={styles.sectionTitle}>Astrological Details</h3>
      {profileData.showastro ? (
        <>
{/* Belief in Horoscope */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Belief in Horoscope:</div>
  <div style={styles.infoValue}>
    {astroDetails.notbeleiveinhor ? 'Does not believe in horoscope' : 'Believes in horoscope'}
  </div>
</div>

{/* Astrological Star */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Astrological Star (Nakshatra):</div>
  <div style={styles.infoValue}>{astroDetails.astro_star || 'Not given'}</div>
</div>

{/* Astrological Rasi (Zodiac Sign) */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Rasi (Zodiac Sign):</div>
  <div style={styles.infoValue}>{astroDetails.astro_rasi || 'Not given'}</div>
</div>

{/* Astrological Padam */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Astrological Padam:</div>
  <div style={styles.infoValue}>{astroDetails.astro_padam || 'Not given'}</div>
</div>

{/* Astrological Pavam */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Astrological Pavam:</div>
  <div style={styles.infoValue}>{astroDetails.astro_pavam || 'Not given'}</div>
</div>

{/* Birthday Time */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Birthday Time:</div>
  <div style={styles.infoValue}>{astroDetails.astro_birthdaytime || 'Not given'}</div>
</div>

{/* Chevai Dosham (Mangalik) */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Chevai Dosham (Mangalik):</div>
  <div style={styles.infoValue}>{astroDetails.astroChevaiThosam ? 'Yes' : 'No'}</div>
</div>

{/* Astrological Chart */}
<div style={styles.infoRow}>
  <div style={styles.infoLabel}>Astrological Chart:</div>
  <div style={styles.infoValue}>
    {astroDetails.astro_ChartFile ? (
      <a href={astroDetails.astro_ChartFile} target="_blank" rel="noopener noreferrer">
        View Chart
      </a>
    ) : (
      'Not given'
    )}
  </div>
</div>

        </>
      ) : (
        <p style={styles.politeMessage}>
          {astroDetails.message}
        </p>
      )}
    </div>
  );
};

export default ProfilePage;
