import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './profile/AuthContext.js';
import { API_URL } from '../common/AppControl.js';
import { GetHeaders } from '../User/Service/ProfileCx.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const MyApprovals = () => {
  const { token } = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // Fixed page size
  const [totalCount, setTotalCount] = useState(0);
  const [rejectionReasons, setRejectionReasons] = useState({}); // Store rejection reasons for each request

  // Fetch data from the API
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(
          `${API_URL}pp/myapprovals?page=${page}&pageSize=${pageSize}`,
          {
            headers: GetHeaders(true, token), // auth required, change it to false if it does not
          }
        );
        setPermissions(response.data.items);
        setFilteredPermissions(response.data.items);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.error("Error fetching permissions", error);
      }
    };

    fetchPermissions();
  }, [page, token]);

  // Handle search and filter
  useEffect(() => {
    let updatedPermissions = [...permissions];

    // Filter by search text
    if (searchText) {
      updatedPermissions = updatedPermissions.filter(
        (item) =>
          item.requesterName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.receiverName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filter by status
    if (statusFilter !== '') {
      updatedPermissions = updatedPermissions.filter(
        (item) => item.isApproved === parseInt(statusFilter)
      );
    }

    setFilteredPermissions(updatedPermissions);
  }, [searchText, statusFilter, permissions]);

  // Handle pagination
  const handleNextPage = () => {
    if (page * pageSize < totalCount) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  // Handle approval/rejection
  const handleApproval = async (id, status, reason = '') => {
    try {
      const response = await axios.post(
        `${API_URL}pp/approveOrReject`,
        {
          id,
          isApproved: status,
          reason: status === 2 ? reason : '', // Send reason only for rejection
        },
        {
          headers: GetHeaders(true, token),
        }
      );

      if (response.data.success) {
        toast.success(response.data.message); // Show success message
      } else {
        toast.error(response.data.message); // Show error message if success is false
      }

      // Refetch permissions after approval/rejection
      setPage(0); // Reset page to refresh data
    } catch (error) {
      toast.error("Error updating request status"); // Show error message if there is an exception
      console.error("Error updating request status", error);
    }
  };

  // Render the status
  const renderStatus = (isApproved,reason) => {
    switch (isApproved) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Rejected:"+reason||'Not Given';
      default:
        return "Unknown";
    }
  };
 // Confirm Approval using SweetAlert
 const confirmApproval = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to approve this request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, approve it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleApproval(id, 1); // Call approval function if confirmed
      }
    });
  };

  // Confirm Rejection using SweetAlert
  const confirmRejection = (id) => {
    const reason = rejectionReasons[id] || ''; // Get rejection reason
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reject this request.',
      input: 'text',
      inputPlaceholder: 'Enter rejection reason (optional)',
      inputValue: reason,
      showCancelButton: true,
      confirmButtonText: 'Yes, reject it!',
      cancelButtonText: 'No, cancel',
      preConfirm: (inputValue) => {
        setRejectionReasons({ ...rejectionReasons, [id]: inputValue });
        handleApproval(id, 2, inputValue); // Call rejection function with reason if confirmed
      }
    });
  };

  return (
    <div>
      <h1>My Approvals</h1>

      {/* Search and Filter Section */}
      <div>
        <input
          type="text"
          placeholder="Search by Requester or Receiver"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="">All Status</option>
          <option value="0">Pending</option>
          <option value="1">Approved</option>
          <option value="2">Rejected</option>
        </select>
      </div>

      {/* Data Table */}
      <table>
        <thead>
          <tr>
            <th>My Profile</th>
            <th>Receiver Profile</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredPermissions.map((item) => (
            <tr key={item.id}>
              <td>
                <a href={`/profile/${item.requestProfileId}`} target="_blank" rel="noopener noreferrer">
                  {item.requesterName}
                </a>
              </td>
              <td>
                <a href={`/profile/${item.receiverProfileId}`} target="_blank" rel="noopener noreferrer">
                  {item.receiverName}
                </a>
              </td>
              <td>{renderStatus(item.isApproved,item.reason)}</td>
              <td>
              {item.isApproved === 0 && (
                  <div>
                    {/* Approve Button */}
                    <button onClick={() => confirmApproval(item.id)}>Approve</button>

                    {/* Reject Button */}
                    <div>
                      <button onClick={() => confirmRejection(item.id)}>
                        Reject
                      </button>
                      {/* Rejection Reason Input */}
                      <input maxLength={100}
                        type="text"
                        placeholder="Reason (optional)"
                        value={rejectionReasons[item.id] || ''}
                        onChange={(e) =>
                          setRejectionReasons({
                            ...rejectionReasons,
                            [item.id]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div>
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <span> Page {page} </span>
        <button onClick={handleNextPage} disabled={page * pageSize >= totalCount}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MyApprovals;
