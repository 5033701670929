import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terms and Conditions</h1>

      <p style={styles.paragraph}>
        Welcome to our matrimonial portal. By using our services, you agree to the following terms and conditions. Please read them carefully before proceeding.
      </p>

      <h2 style={styles.subtitle}>Data Sharing</h2>
      <p style={styles.paragraph}>
        We take your privacy seriously. As part of our services, your data may be shared with other online platforms operated by Confluence Ventures (Pvt) Ltd to improve your experience. We ensure that your data is handled responsibly and securely in compliance with applicable privacy laws. For more information about our services, please visit 
        <a href="https://www.cventures.lk" target="_blank" rel="noopener noreferrer" style={styles.link}> Confluence Ventures (Pvt) Ltd</a>.
      </p>

      <h2 style={styles.subtitle}>Accuracy of Information</h2>
      <p style={styles.paragraph}>
        By using our platform, you agree that all the information you provide is accurate to the best of your knowledge. We do not have the capacity to independently verify or validate the details provided by users. It is your sole responsibility to ensure that the information you submit is correct. If any false or misleading information is entered, we cannot be held responsible for the consequences.
      </p>

      <h2 style={styles.subtitle}>Verification of Information</h2>
      <p style={styles.paragraph}>
        We do not provide verification services for the data entered on this platform. It is your responsibility to verify the authenticity and accuracy of the details of your potential spouse through trusted personal, familial, or legal channels. We strongly encourage you to exercise due diligence before making any important life decisions.
      </p>

      <h2 style={styles.subtitle}>Limitations of Liability</h2>
      <p style={styles.paragraph}>
        As a free matrimonial service, we aim to facilitate meaningful connections. However, Confluence Ventures (Pvt) Ltd and this platform do not assume any responsibility for the accuracy, truthfulness, or legality of the information provided by users. We are not liable for any financial, emotional, or legal outcomes arising from false or misleading information entered by other users.
      </p>

      <h2 style={styles.subtitle}>Serving Sri Lankan Nationalities</h2>
      <p style={styles.paragraph}>
        Our platform is designed to serve Sri Lankan nationalities, including Tamils, Indian Tamils, Sinhalese, and others. We are proud to provide a space for individuals of diverse cultural and ethnic backgrounds to connect in a respectful and secure environment.
      </p>

      <h2 style={styles.subtitle}>User Conduct</h2>
      <p style={styles.paragraph}>
        Users are expected to behave respectfully and professionally on this platform. Any inappropriate, fraudulent, or misleading behavior will result in the suspension or termination of access to our services. We reserve the right to take appropriate actions to protect the integrity of the platform and the safety of all users.
      </p>

      <h2 style={styles.subtitle}>Amendments to the Terms</h2>
      <p style={styles.paragraph}>
        These Terms and Conditions may be updated from time to time to reflect changes in our services or legal obligations. You are encouraged to review this page periodically for any updates.
      </p>

      <p style={styles.signature}>
        Thank you for using our service. We are committed to providing a secure and supportive environment for matrimonial connections.
      </p>

      <p style={styles.signature}>
        Sincerely,<br />
        The Team at Confluence Ventures (Pvt) Ltd
      </p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f9f9f9',
    padding: '40px',
    borderRadius: '10px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '900px',
    margin: '40px auto',
    color: '#333',
  },
  title: {
    color: '#007bff',
    textAlign: 'center',
    marginBottom: '20px',
  },
  subtitle: {
    color: '#333',
    marginBottom: '10px',
    fontSize: '18px',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  signature: {
    fontSize: '16px',
    marginTop: '40px',
    fontStyle: 'italic',
    textAlign: 'center',
  },
};

export default TermsAndConditions;
