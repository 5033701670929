// ProfileSearchContainer.js
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ProfileSearch from './ProfileSearch';
import ProfileList from './ProfileList';

const ProfileSearchContainer = ({ sex }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = useParams();

  // Function to map gender text to sx value
  const getGenderValue = (gender) => {
    if (gender.toLowerCase() === 'bride') return 2;
    if (gender.toLowerCase() === 'groom') return 1;
    if (gender.toLowerCase() === 'brides') return 2;
    if (gender.toLowerCase() === 'grooms') return 1;
    return 1; // default to Male
  };

  // Initialize search parameters from URL or default values
  const [searchParams, setSearchParams] = useState({
    sx:sex|| Number(queryParams.get('sx')) || getGenderValue(params.gender || ''),
    ms: Number(params.ms) || Number(queryParams.get('ms')) || 0,
    re: Number(params.re) || Number(queryParams.get('re')) || 0,
    ec: Number(params.ec) || Number(queryParams.get('ec')) || 0,
    mt: Number(params.mt) || Number(queryParams.get('mt')) || 0,
    ed: Number(params.ed) || Number(queryParams.get('ed')) || 0,
    oc: Number(params.oc) || Number(queryParams.get('oc')) || 0,
    rc: Number(params.rc) || Number(queryParams.get('rc')) || 0,
    af: Number(queryParams.get('af')) || 18,
    at: Number(queryParams.get('at')) || 70,
    st: queryParams.get('st') || '',
  });

  const [currentPage, setCurrentPage] = useState(1);

  // Update search parameters when URL changes
  useEffect(() => {
    const updatedParams = {
      sx: Number(queryParams.get('sx')) || getGenderValue(params.gender || ''),
      ms: Number(queryParams.get('ms')) ||Number(params.ms) || 0,
      re: Number(params.re) || 0,
      ec: Number(params.ec) || 0,
      mt: Number(params.mt) || 0,
      ed: Number(params.ed) || 0,
      oc: Number(params.oc) || 0,
      rc: Number(params.rc) || 0,
      af: Number(queryParams.get('af')) || 18,
      at: Number(queryParams.get('at')) || 70,
      st: queryParams.get('st') || '',
    };
    setSearchParams(updatedParams);
  }, [location.search, params]);

  // Handle search form submission
  const handleSearch = (formData) => {
    setSearchParams(formData);
    setCurrentPage(1); // Reset to page 1 when a new search is submitted
  };

  return (
    <div className="container my-4">
      <ProfileSearch onSearch={handleSearch} initialValues={searchParams} />
      <ProfileList
        searchParams={searchParams}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ProfileSearchContainer;
