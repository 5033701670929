import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css'; // Assuming you add the corresponding CSS

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="notfound-container">
      <h1 className="notfound-title">404 - Page Not Found</h1>
      <p className="notfound-message">
        Oops! The page you're looking for doesn't exist. It might have been moved or deleted.
      </p>
     
      <button onClick={() => navigate('/')} className="notfound-button">
        Go Back to Homepage
      </button>
    </div>
  );
}

export default NotFound;
