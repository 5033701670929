import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; // Import Toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS
import { API_URL } from '../common/AppControl';

const ContactUs = () => {
  const [recaptchaReady, setRecaptchaReady] = useState(false);

  // Form validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    message: Yup.string().required('Message is required').max(500),
    subject: Yup.string().required('Please select a subject'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Wait for grecaptcha to be available
  useEffect(() => {
    const waitForGrecaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => setRecaptchaReady(true));
      } else {
        // Retry after a short delay if grecaptcha is not available yet
        setTimeout(waitForGrecaptcha, 500);
      }
    };
    waitForGrecaptcha();
  }, []);

  const onSubmit = async (data) => {
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }

    // Trigger reCAPTCHA v3 and get the token
    window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' }).then(async (token) => {
      const submissionData = {
        ...data,
        captchaToken: token,
      };

      try {
        const response = await axios.post(`${API_URL}contact`, submissionData);

        if (response.data.success) {
          toast.success('Message sent successfully!');
          reset();
        } else {
          toast.error('Failed to send message: ' + response.data.msg);
        }
      } catch (error) {
        console.error(error);
        toast.error('An error occurred while sending your message.');
      }
    });
  };

  return (
    <div className="contact-us-form">
      <ToastContainer /> {/* Toast container for notifications */}

      <h2>Contact Us</h2>
      <div style={{ backgroundColor: '#f0f8ff', padding: '20px', borderRadius: '10px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
        <h2 style={{ color: '#007bff', textAlign: 'center' }}>Welcome to Our Free Matrimonial Service</h2>
        <p>
          We are a small team of dedicated volunteers, managing this platform in our spare time alongside our busy schedules.
          While we aim to respond to all inquiries within <strong>three working days</strong>, please understand that our resources are limited.
        </p>
        <p>
          To help us serve you better, we kindly ask that you refrain from attempting to visit or contact us by phone,
          as we are unable to accommodate in-person visits or calls at this time.
        </p>
        <p style={{ textAlign: 'center' }}>
          We truly appreciate your understanding and patience.
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Name:</label>
          <input name="name" type="text" {...register('name')} />
          <p style={{ color: 'red' }}>{errors.name?.message}</p> {/* Error message in red */}
        </div>
        <div>
          <label>Email:</label>
          <input name="email" type="email" {...register('email')} />
          <p style={{ color: 'red' }}>{errors.email?.message}</p> {/* Error message in red */}
        </div>
        <div>
          <label>Subject:</label>
          <select name="subject" {...register('subject')}>
            <option value="">Please select</option>
            <option value="I want to close my account">I want to close my account</option>
            <option value="Translation Services (Paid)">Translation Services (Paid)</option>
            <option value="Astrological Services (Paid)">Astrological Services (Paid)</option>
            <option value="Increase my allowed profile limit">Increase my allowed profile limit</option>
            <option value="Change my profile DOB/Sex">Change my profile DOB/Sex</option>
            <option value="Other Issues">Other Issues</option>
          </select>
          <p style={{ color: 'red' }}>{errors.subject?.message}</p> {/* Error message in red */}
        </div>
        <div>
          <label>Message:</label>
          <textarea name="message" {...register('message')}></textarea>
          <p style={{ color: 'red' }}>{errors.message?.message}</p> {/* Error message in red */}
        </div>

        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUs;
