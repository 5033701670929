// Login.js
import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { API_IDENTITY_URL } from '../../lib/constants';
import { API_IDENTITY_URL } from '../../common/AppControl';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const setAuthTokenInCookie = (token, daysToExpire) => {
    const expires = new Date(Date.now() + daysToExpire * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `authToken=${token}; expires=${expires}; path=/; Secure; SameSite=Strict`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API_IDENTITY_URL}login`, {
        domain: window.location.hostname,
        email: email,
        password: password,
        rememberMe: true,
        returnUrl: '',
      });

      const data = response.data;

      if (data.returnCode === 1) {
        login(data.token,data.cxName);
        toast.success('Login successful, navigating to dashboard...');
        setAuthTokenInCookie(data.token, 7);
        navigate('/dashboard');
      } else {
        toast.error(data.returnText || 'Login failed');
      }
    } catch (error) {
      console.error('Login failed', error);
      toast.error('Error logging in');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Your Email Address"
            required
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Your Password"
            required
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: loading ? 'not-allowed' : 'pointer',
            width: '100%',
          }}
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
