// ProfileList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { API_URL, ITEMS_PER_PAGE } from '../common/config';
import { API_URL, ITEMS_PER_PAGE } from '../common/AppControl';
import { Card, Row, Col, Pagination, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure Bootstrap Icons are imported

const ProfileList = ({ searchParams, currentPage, setCurrentPage }) => {
  const [profiles, setProfiles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch profiles from API
  const fetchProfiles = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${API_URL}pp/search`, {
        params: {
          ...searchParams, // Include search parameters in the request
          page: currentPage,
          pageSize: ITEMS_PER_PAGE,
        },
      });

      if (response.data && response.data.items) {
        setProfiles(response.data.items);
        setTotalCount(response.data.totalCount); // Set total count from API response
      } else {
        setProfiles([]);
        setTotalCount(0);
      }
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  // Fetch profiles when searchParams or currentPage changes
  useEffect(() => {
    fetchProfiles();
  }, [searchParams, currentPage]);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const pageNeighbours = 2; // Adjust as needed

  // Initialize pagination items array
  const paginationItems = [];

  // Calculate start and end page numbers
  const startPage = Math.max(1, currentPage - pageNeighbours);
  const endPage = Math.min(totalPages, currentPage + pageNeighbours);

  // Add '<<' and '<' buttons if not on the first page
  if (currentPage > 1) {
    paginationItems.push(
      <Pagination.Item key="first" onClick={() => handlePageChange(1)}>
        {'<<'}
      </Pagination.Item>
    );
    paginationItems.push(
      <Pagination.Item key="prev" onClick={() => handlePageChange(currentPage - 1)}>
        {'<'}
      </Pagination.Item>
    );
  }

  // Add first page and ellipsis if necessary
  if (startPage > 1) {
    paginationItems.push(
      <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>
    );
    if (startPage > 2) {
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }
  }

  // Add page numbers around current page
  for (let number = startPage; number <= endPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Add ellipsis and last page if necessary
  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }
    paginationItems.push(
      <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </Pagination.Item>
    );
  }

  // Add '>' and '>>' buttons if not on the last page
  if (currentPage < totalPages) {
    paginationItems.push(
      <Pagination.Item key="next" onClick={() => handlePageChange(currentPage + 1)}>
        {'>'}
      </Pagination.Item>
    );
    paginationItems.push(
      <Pagination.Item key="last" onClick={() => handlePageChange(totalPages)}>
        {'>>'}
      </Pagination.Item>
    );
  }

  // Define styles object
  const styles = {
    icons: {
      display: 'flex',
      gap: '10px',
      marginTop: '10px',
    },
    mangalikIcon: {
      color: 'red', // Color for Mangalik icon
      fontSize: '20px',
    },
    chartIcon: {
      color: 'blue', // Color for Chart Attached icon
      fontSize: '20px',
    },
  };

  return (
    <div className="mt-5">
      <h2 className="mb-4">Profile Search Results</h2>

      {loading && (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      )}

      {error && <Alert variant="danger">{error}</Alert>}

      {!loading && !error && profiles.length === 0 && (
        <Alert variant="info">No profiles found.</Alert>
      )}

      {!loading && !error && profiles.length > 0 && (
        <>
          <Row>
            {profiles.map((profile) => {
              const searchResults = JSON.parse(profile.searchResults);

              return (
                <Col md={4} key={searchResults.profileid} className="mb-4">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={searchResults.defaultimage}
                      alt={searchResults.name}
                      style={{ height: '200px', objectFit: 'cover' }}
                    />
                      <Card.Text>

<strong>Last Seen:</strong>{new Date(profile.lastSeen).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}

</Card.Text>


                    <Card.Body>
                      <Card.Title>{searchResults.name}</Card.Title>
                      <Card.Text>
                      
                        <strong>Age:</strong> {searchResults.age} <br />
                        <strong>Living City:</strong> {searchResults.livingcity || 'Not provided'} <br />
                        <strong>Living Country:</strong> {searchResults.residentcountry || 'Not provided'} <br />
                        <strong>Ethnicity:</strong> {searchResults.ethnicity} <br />
                        <strong>Religion:</strong> {searchResults.religion} <br />
                        <strong>Marital Status:</strong> {searchResults.martialstatus} <br />
                        <strong>Occupation:</strong> {searchResults.occupation || 'Not provided'} <br />
                      </Card.Text>

                      {/* Icons Section */}
                      <div style={styles.icons}>
                        {searchResults.astroChevaiThosam && (
                          <div title="Mangalik">
                            <i
                              className="bi bi-exclamation-triangle-fill"
                              style={styles.mangalikIcon}
                            ></i>
                          </div>
                        )}
                        {searchResults.ChartAvailable && (
                          <div title="Chart Attached">
                            <i
                              className="bi bi-file-earmark-fill"
                              style={styles.chartIcon}
                            ></i>
                          </div>
                        )}
                      </div>

                      <a href={`/profile/${searchResults.profileURL}`}  className="btn btn-primary mt-3">
                        View Profile
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>

          {/* Pagination Controls */}
          {totalPages > 1 && <Pagination>{paginationItems}</Pagination>}
        </>
      )}
    </div>
  );
};

export default ProfileList;
