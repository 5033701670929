import React from 'react';

const AboutUs = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>About Us</h1>
      <p style={styles.paragraph}>
        Welcome to our matrimonial portal, a platform designed with great care to help connect individuals seeking meaningful relationships. 
        Our service is dedicated to serving Sri Lankan nationals of diverse backgrounds, including Tamils, Indian Tamils, Sinhalese, and more. 
        Whether you're seeking a partner from within your cultural heritage or beyond, we are here to make the journey easier.
      </p>

      <p style={styles.paragraph}>
        We understand that finding a life partner is one of the most significant decisions in life. 
        That's why our portal is tailored to provide a user-friendly, secure, and respectful space for all our users. 
        Our goal is to support your search for a partner in a compassionate and professional manner.
      </p>

      <p style={styles.paragraph}>
        Our platform is developed and managed by <a href="https://www.cventures.lk" target="_blank" rel="noopener noreferrer" style={styles.link}>Confluence Ventures (Pvt) Ltd</a>, 
        a reputable company committed to providing innovative and reliable online services. We pride ourselves on maintaining the highest standards of service quality and customer care.
      </p>

      <p style={styles.paragraph}>
        We believe in fostering connections that go beyond borders, and we are honored to be a part of your journey in finding your life partner. 
        If you have any questions or need support, please feel free to reach out to us through the contact section of the website.
      </p>

      <p style={styles.signature}>
        Thank you for choosing us, and we wish you all the best in your search.
      </p>

      <p style={styles.signature}>
        Sincerely,<br />
        The Team at Confluence Ventures (Pvt) Ltd
      </p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f9f9f9',
    padding: '40px',
    borderRadius: '10px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '40px auto',
    color: '#333',
  },
  title: {
    color: '#007bff',
    textAlign: 'center',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  signature: {
    fontSize: '16px',
    marginTop: '40px',
    fontStyle: 'italic',
    textAlign: 'center',
  },
};

export default AboutUs;
