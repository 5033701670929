import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProfileByIdCx,deleteImageCx, deleteProfileCx,publishProfileCx } from './Profile/Service/ProfileCx';
import { useAuth } from  '../contents/profile/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const ProfileDetail = () => {
  const {token}=useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    const data = await fetchProfileByIdCx(id,token);
    setItem(data);
  };
  const handleDelete = async () => {
    try {
      //await publishProfileCx(id,profileid, token);
      await deleteProfileCx(id,token);
      toast.dismiss(); // Close the confirmation toast
      toast.success("Profile deleted successfully!"); // Success toast
      fetchData(); // Refresh the list
    } catch (error) {
      toast.error("Error occurred while deleting the profile."); // Error toast
    }

  };
  const handlePublish = async () => {
    try {
      await publishProfileCx(id,item.fk_custid, token);
      toast.dismiss(); // Close the confirmation toast
      toast.success("Profile published successfully!"); // Success toast
      fetchData(); // Refresh the list
    } catch (error) {
      toast.error("Error occurred while publishing the profile."); // Error toast
    }
  };
  const handleDeleteImage = async (imageId) => {
    try {
      // Show confirmation using SweetAlert2
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this image!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Call API to delete the image (replace this with your delete image logic)
          await deleteImageCx(imageId,id, token);
          toast.success("Image deleted successfully!");
          fetchData(); // Refresh the data
        }
      });
    } catch (error) {
      toast.error("Error occurred while deleting the image."); // Error toast
    }
  };

  if (!item) return <div>Loading...</div>;
  return (
    <div>
      <h1>Profile Details</h1><p><strong>Profileid:</strong> {item.profileid}</p>
      <p><strong>Fk custid:</strong> {item.fk_custid}</p>
      <p><strong>Name:</strong> {item.name}</p>
      <p><strong>Createdby:</strong> {item.createdby}</p>
      <p><strong>Martialstatus:</strong> {item.martialstatus}</p>
      <p><strong>Dob:</strong> {item.dob}</p>
      <p><strong>Sex:</strong> {item.sex}</p>
      <p><strong>Occupation:</strong> {item.occupation}</p>
      <p><strong>Residentcountry:</strong> {item.residentcountry}</p>
      <p><strong>Citizencountry:</strong> {item.citizencountry}</p>
      <p><strong>Caste:</strong> {item.caste}</p>
      <p><strong>Castenobar:</strong> {item.castenobar}</p>
      <p><strong>Qualification:</strong> {item.qualification}</p>
      <p><strong>Education:</strong> {item.education}</p>
      <p><strong>Ethnicity:</strong> {item.ethnicity}</p>
      <p><strong>Mother Tonque:</strong> {item.motherTonque}</p>
      <p><strong>Age:</strong> {item.age}</p>
      <p><strong>Complexion:</strong> {item.complexion}</p>
      <p><strong>Foodhabits:</strong> {item.foodhabits}</p>
      <p><strong>Religion:</strong> {item.religion}</p>
      <p><strong>Sponsor:</strong> {item.sponsor}</p>
      <p><strong>Annualincome:</strong> {item.annualincome}</p>
      <p><strong>Additionaloccupation:</strong> {item.additionaloccupation}</p>
      <p><strong>Additionalqualification:</strong> {item.additionalqualification}</p>
      <p><strong>Additionalcaste:</strong> {item.additionalcaste}</p>
      <p><strong>Notbeleiveinhor:</strong> {item.notbeleiveinhor}</p>
      <p><strong>Birthcountry:</strong> {item.birthcountry}</p>
      <p><strong>Birthcity:</strong> {item.birthcity}</p>
      <p><strong>Livingcity:</strong> {item.livingcity}</p>
      <p><strong>Height:</strong> {item.height}</p>
      <p><strong>Weight:</strong> {item.weight}</p>
      <p><strong>Height Type:</strong> {item.heightType}</p>
      <p><strong>Weight Type:</strong> {item.weightType}</p>
      <p><strong>Other:</strong> {item.other}</p>
      <p><strong>Email:</strong> {item.email}</p>
      <p><strong>Contactname:</strong> {item.contactname}</p>
      <p><strong>Organization:</strong> {item.organization}</p>
      <p><strong>Address1:</strong> {item.address1}</p>
      <p><strong>Address2:</strong> {item.address2}</p>
      <p><strong>Address3:</strong> {item.address3}</p>
      <p><strong>City:</strong> {item.city}</p>
      <p><strong>Postcode:</strong> {item.postcode}</p>
      <p><strong>Country:</strong> {item.country}</p>
      <p><strong>Phone1:</strong> {item.phone1}</p>
      <p><strong>Phone2:</strong> {item.phone2}</p>
      <p><strong>Fax:</strong> {item.fax}</p>
      <p><strong>Mobile:</strong> {item.mobile}</p>
       <p><strong>Ex other:</strong> {item.ex_other}</p>
      <p><strong>Astro star:</strong> {item.astro_star}</p>
      <p><strong>Astro rasi:</strong> {item.astro_rasi}</p>
      <p><strong>Astro padam:</strong> {item.astro_padam}</p>
      <p><strong>Astro pavam:</strong> {item.astro_pavam}</p>
      <p><strong>Astro birthdaytime:</strong> {item.astro_birthdaytime}</p>
      <p><strong>Astro  Chevai Thosam:</strong> {item.astro_ChevaiThosam}</p>
{/* Conditional Astro Chart File link */}
{item.astro_ChartFile && (
    <p>
      <strong>Astro Chart File:</strong>
      <a href={item.astro_ChartFile} target="_blank" rel="noopener noreferrer">Astro file</a>
    </p>
  )}  <p><strong>Is Gold:</strong> {item.isGold}</p>
      <p><strong>View Count:</strong> {item.viewCount}</p>
      <p><strong>Imagecount:</strong> {item.imagecount}</p>
      <p><strong>Reviewed:</strong> {item.reviewed}</p>
      <p><strong>Lastloginweek:</strong> {item.lastloginweek}</p>
      <p><strong>Lastlogin:</strong> {item.lastlogin}</p>
      <p><strong>Matrimonyid:</strong> {item.matrimonyid}</p>
      <p><strong>Random No:</strong> {item.randomNo}</p>
      <p><strong>Horprotected:</strong> {item.horprotected}</p>
      <p><strong>Photoprotected:</strong> {item.photoprotected}</p>
      <p><strong>Contactprotected:</strong> {item.contactprotected}</p>
      <p><strong>Admin Override Code:</strong> {item.adminOverrideCode}</p>
      <p><strong>Active:</strong> {item.active}</p>
      <p><strong>Modifiedby:</strong> {item.modifiedby}</p>
      <p><strong>Modifiedon:</strong> {item.modifiedon}</p>
      <p><strong>Addedon:</strong> {item.addedon}</p>
      <p><strong>Title:</strong> {item.title}</p>
   {/* Conditional Default Image Display */}
   {item.defaultImageName && (
    <p>
      <strong>Default Image:</strong>
      <img
        src={item.defaultImageName}
        alt="Default Profile"
        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
      />
    </p>
  )}
       <p><strong>Bodytype:</strong> {item.bodytype}</p>
      <p><strong>New Cust I D:</strong> {item.newCustID}</p>
           {/* Display Images */}
           <h2>Profile Images</h2>
      <div className="profile-images">
        {item.adminImage && item.adminImage.length > 0 ? (
          item.adminImage.map((image) => (
            <div key={image.imageId} style={{ marginBottom: '15px' }}>
              <img
                src={image.imageLocation}
                alt="Profile"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
              />
              <br />
              <button
                style={{ marginTop: '10px', color: 'red' }}
                onClick={() => handleDeleteImage(image.imageId)}
              >
                Delete Image
              </button>
            </div>
          ))
        ) : (
          <p>No images available.</p>
        )}
      </div>

      <button onClick={() => navigate(`/admin/profile/edit/${item.profileid}`)}>Edit</button>
      <button onClick={handleDelete}>Delete</button>
      <button onClick={() => navigate(`/admin/profile`)}>Back</button>
      <div>
      <button onClick={handlePublish}>Publish This Profile</button>      
      </div>
    </div>
  );
};
export default ProfileDetail;