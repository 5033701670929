import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProfileByIdCx, deleteProfileCx } from './Service/ProfileCx';
import { useAuth } from  '../contents/profile/AuthContext';

const ProfileDetail = () => {
  const {token}=useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  useEffect(() => {
    fetchData();
  }, [id]);
  const fetchData = async () => {
    const data = await fetchProfileByIdCx(id,token);
    setItem(data);
  };
  const handleDelete = async () => {
    await deleteProfileCx(id);
    navigate(`/Profiles`);
  };
  if (!item) return <div>Loading...</div>;
  return (
    <div>
      <h1>Profile Details</h1>      <p><strong>Profileid:</strong> {item.profileid}</p>
      <p><strong>Fk custid:</strong> {item.fk_custid}</p>
      <p><strong>Name:</strong> {item.name}</p>
      <p><strong>Createdby:</strong> {item.createdby}</p>
      <p><strong>Martialstatus:</strong> {item.martialstatus}</p>
      <p><strong>Dob:</strong> {item.dob}</p>
      <p><strong>Sex:</strong> {item.sex}</p>
      <p><strong>Occupation:</strong> {item.occupation}</p>
      <p><strong>Residentcountry:</strong> {item.residentcountry}</p>
      <p><strong>Citizencountry:</strong> {item.citizencountry}</p>
      <p><strong>Caste:</strong> {item.caste}</p>
      <p><strong>Castenobar:</strong> {item.castenobar}</p>
      <p><strong>Qualification:</strong> {item.qualification}</p>
      <p><strong>Education:</strong> {item.education}</p>
      <p><strong>Ethnicity:</strong> {item.ethnicity}</p>
      <p><strong>Mother Tonque:</strong> {item.motherTonque}</p>
      <p><strong>Age:</strong> {item.age}</p>
      <p><strong>Complexion:</strong> {item.complexion}</p>
      <p><strong>Foodhabits:</strong> {item.foodhabits}</p>
      <p><strong>Religion:</strong> {item.religion}</p>
      <p><strong>Sponsor:</strong> {item.sponsor}</p>
      <p><strong>Annualincome:</strong> {item.annualincome}</p>
      <p><strong>Additionaloccupation:</strong> {item.additionaloccupation}</p>
      <p><strong>Additionalqualification:</strong> {item.additionalqualification}</p>
      <p><strong>Additionalcaste:</strong> {item.additionalcaste}</p>
      <p><strong>Notbeleiveinhor:</strong> {item.notbeleiveinhor}</p>
      <p><strong>Birthcountry:</strong> {item.birthcountry}</p>
      <p><strong>Birthcity:</strong> {item.birthcity}</p>
      <p><strong>Livingcity:</strong> {item.livingcity}</p>
      <p><strong>Height:</strong> {item.height}</p>
      <p><strong>Weight:</strong> {item.weight}</p>
      <p><strong>Height Type:</strong> {item.heightType}</p>
      <p><strong>Weight Type:</strong> {item.weightType}</p>
      <p><strong>Other:</strong> {item.other}</p>
      <p><strong>Email:</strong> {item.email}</p>
      <p><strong>Contactname:</strong> {item.contactname}</p>
      <p><strong>Organization:</strong> {item.organization}</p>
      <p><strong>Address1:</strong> {item.address1}</p>
      <p><strong>Address2:</strong> {item.address2}</p>
      <p><strong>Address3:</strong> {item.address3}</p>
      <p><strong>City:</strong> {item.city}</p>
      <p><strong>Postcode:</strong> {item.postcode}</p>
      <p><strong>Country:</strong> {item.country}</p>
      <p><strong>Phone1:</strong> {item.phone1}</p>
      <p><strong>Phone2:</strong> {item.phone2}</p>
      <p><strong>Fax:</strong> {item.fax}</p>
      <p><strong>Mobile:</strong> {item.mobile}</p>
      <p><strong>Phone1verified:</strong> {item.phone1verified}</p>
      <p><strong>Phone2verified:</strong> {item.phone2verified}</p>
      <p><strong>Mobileverified:</strong> {item.mobileverified}</p>
      <p><strong>Ex other:</strong> {item.ex_other}</p>
      <p><strong>Astro star:</strong> {item.astro_star}</p>
      <p><strong>Astro rasi:</strong> {item.astro_rasi}</p>
      <p><strong>Astro padam:</strong> {item.astro_padam}</p>
      <p><strong>Astro pavam:</strong> {item.astro_pavam}</p>
      <p><strong>Astro birthdaytime:</strong> {item.astro_birthdaytime}</p>
      <p><strong>Astro  Chevai Thosam:</strong> {item.astro_ChevaiThosam}</p>
      <p><strong>Astro  Chart File:</strong> {item.astro_ChartFile}</p>
      <p><strong>Annonmessages:</strong> {item.annonmessages}</p>
      <p><strong>Package I D:</strong> {item.packageID}</p>
      <p><strong>Expires On:</strong> {item.expiresOn}</p>
      <p><strong>Paid On:</strong> {item.paidOn}</p>
      <p><strong>Amount Paid:</strong> {item.amountPaid}</p>
      <p><strong>Paid Currency:</strong> {item.paidCurrency}</p>
      <p><strong>Pay Comments:</strong> {item.payComments}</p>
      <p><strong>Remove Comments:</strong> {item.removeComments}</p>
      <p><strong>Is Gold:</strong> {item.isGold}</p>
      <p><strong>Orderstatus:</strong> {item.orderstatus}</p>
      <p><strong>View Count:</strong> {item.viewCount}</p>
      <p><strong>Imagecount:</strong> {item.imagecount}</p>
      <p><strong>Reviewed:</strong> {item.reviewed}</p>
      <p><strong>Lastloginweek:</strong> {item.lastloginweek}</p>
      <p><strong>Lastlogin:</strong> {item.lastlogin}</p>
      <p><strong>Matrimonyid:</strong> {item.matrimonyid}</p>
      <p><strong>Random No:</strong> {item.randomNo}</p>
      <p><strong>Horprotected:</strong> {item.horprotected}</p>
      <p><strong>Photoprotected:</strong> {item.photoprotected}</p>
      <p><strong>Contactprotected:</strong> {item.contactprotected}</p>
      <p><strong>Admin Override Code:</strong> {item.adminOverrideCode}</p>
      <p><strong>Active:</strong> {item.active}</p>
      <p><strong>Modifiedby:</strong> {item.modifiedby}</p>
      <p><strong>Modifiedon:</strong> {item.modifiedon}</p>
      <p><strong>Addedon:</strong> {item.addedon}</p>
      <p><strong>Title:</strong> {item.title}</p>
      <p><strong>Default Image Name:</strong> {item.defaultImageName}</p>
      <p><strong>Bodytype:</strong> {item.bodytype}</p>
      <p><strong>New Cust I D:</strong> {item.newCustID}</p>
      <p><strong>Createdby Text:</strong> {item.createdbyText}</p>
      <p><strong>Martialstatus Text:</strong> {item.martialstatusText}</p>
      <p><strong>Sex Text:</strong> {item.sexText}</p>
      <p><strong>Occupation Text:</strong> {item.occupationText}</p>
      <p><strong>Residentcountry Text:</strong> {item.residentcountryText}</p>
      <p><strong>Citizencountry Text:</strong> {item.citizencountryText}</p>
      <p><strong>Qualification Text:</strong> {item.qualificationText}</p>
      <p><strong>Education Text:</strong> {item.educationText}</p>
      <p><strong>Ethnicity Text:</strong> {item.ethnicityText}</p>
      <p><strong>Mother Tonque Text:</strong> {item.motherTonqueText}</p>
      <p><strong>Foodhabits Text:</strong> {item.foodhabitsText}</p>
      <p><strong>Religion Text:</strong> {item.religionText}</p>
      <p><strong>Birthcountry Text:</strong> {item.birthcountryText}</p>
      <p><strong>Astro star Text:</strong> {item.astro_starText}</p>
      <p><strong>Astro rasi Text:</strong> {item.astro_rasiText}</p>
      <button onClick={() => navigate(`/Profiles/edit/${item.profileid}`)}>Edit</button>
      <button onClick={handleDelete}>Delete</button>
      <button onClick={() => navigate(`/Profiles`)}>Back</button>
    </div>
  );
};
export default ProfileDetail;