import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contents/profile/AuthContext';

import {
  createProfileCx,
  fetchProfileByIdCx,
  updateProfileCx,
  fetchProfessionData,
  fetchTitleData,
  fetchBodyTypeData,
  fetchComplexionData,
  fetchCreatedbyData,
  fetchEducationData,
  fetchEthnicityData,
  fetchFoodHabitData,
  fetchHeightTypeData,
  fetchMartialStatusData,
  fetchMotherTongueData,
  fetchRasiData,
  fetchReligionData,
  fetchSexData,
  fetchStarData,
  fetchWeightTypeData,
  fetchCountryData,
} from './Profile/Service/ProfileCx';

import {
  TitleSelect,
  ProfessionSelect,
  BodyTypeSelect,
  ComplexionSelect,
  EducationSelect,
  EthnicitySelect,
  FoodHabitSelect,
  HightTypeSelect,
  MartialStatusSelect,
  MotherTonqueSelect,
  RasiSelect,
  ReligionSelect,
  StarSelect,
  WeightTypeSelect,
  CountrySelect,
  CountryMandatorySelect,
  CreatedbySelect,
  SexSelect,
} from './ProfileSelect';

import './ProfileForm.css';
// Function to extract yyyy-MM-dd from a full datetime string
const formatDateToYYYYMMDD = (dateTimeString) => {
  if (!dateTimeString) return '';
  return dateTimeString.split('T')[0]; // Extract the date part
};

const ProfileForm = () => {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    profileid: 0,
    name: '',
    createdby: 0,
    martialstatus: 0,
    dob: '1994-01-01',
    sex: 0,
    occupation: 0,
    residentcountry: 0,
    citizencountry: 0,
    castenobar: false,
    education: 0,
    ethnicity: 0,
    motherTonque: 0,
    complexion: 0,
    foodhabits: 0,
    religion: 0,
    sponsor: true,
    annualincome: '',
    additionaloccupation: '',
    additionalqualification: '',
    additionalcaste: '',
    notbeleiveinhor: false,
    birthcountry: 0,
    birthcity: '',
    livingcity: '',
    height: 0,
    weight: 0,
    heightType: 0,
    weightType: 0,
    other: '',
    email: '',
    contactname: '',
    organization: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    postcode: '',
    country: 0,
    phone1: '',
    phone2: '',
    fax: '',
    mobile: '',
    ex_other: '',
    astro_star: 0,
    astro_rasi: 0,
    astro_padam: 0,
    astro_pavam: 0,
    astro_birthdaytime: '',
    astro_ChevaiThosam: false,
    title: '',
    bodytype: 0,
    horprotected: true,
    photoprotected: true,
    contactprotected: true,
    timeOfBirth:'',
  });

  const [bodytypes, setBodyTypes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [complexions, setComplexions] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [createdbys, setCreatedbys] = useState([]);
  const [educations, setEducations] = useState([]);
  const [ethnicitys, setEthnicitys] = useState([]);
  const [foodhabits, setFoodHabits] = useState([]);
  const [highttypes, setHightTypes] = useState([]);
  const [martialstatuss, setMartialStatuss] = useState([]);
  const [mothertonques, setMotherTonques] = useState([]);
  const [rasis, setRasis] = useState([]);
  const [religions, setReligions] = useState([]);
  const [sexs, setSexs] = useState([]);
  const [stars, setStars] = useState([]);
  const [weighttypes, setWeightTypes] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      loadProfile();
    }
    loadBodyTypes();
    loadTitles();
    loadProfessions();
    loadComplexions();
    loadCreatedbys();
    loadEducations();
    loadEthnicitys();
    loadFoodHabits();
    loadHightTypes();
    loadMartialStatuss();
    loadMotherTonques();
    loadRasis();
    loadReligions();
    loadSexs();
    loadStars();
    loadWeightTypes();
    loadCountrys();
  }, [id]);

  const loadProfile = async () => {
    try {
      const data = await fetchProfileByIdCx(id, token);

      // Map null or undefined values to default values
      const formattedData = Object.fromEntries(
        Object.entries(profile).map(([key, defaultValue]) => [
          key,
          data[key] !== null && data[key] !== undefined ? data[key] : defaultValue,
        ])
      );
  // Extract the date part for dob (yyyy-MM-dd)
  formattedData.dob= formatDateToYYYYMMDD(data.dob);
  formattedData.timeOfBirth= data.astro_birthdaytime ? data.astro_birthdaytime.split('T')[1]?.substr(0, 5) : '';
/*
      // Format dobFormatted and astroDobFormatted
      formattedData.dobFormatted = formattedData.dob ? formattedData.dob.split('T')[0] : '';
      if (formattedData.astro_birthdaytime) {
        const timePart = formattedData.astro_birthdaytime.split('T')[1];
        formattedData.astroDobFormatted = timePart ? timePart.substr(0, 5) : '';
      } else {
        formattedData.astroDobFormatted = '';
      }
*/
      setProfile(formattedData);
    } catch (error) {
      console.error('Failed to load profile:', error);
    }
  };

const loadTitles = async () => {
  try {
    const data = await fetchTitleData();
    setTitles(data);
  } catch (error) {
    console.error('Failed to load titles:', error);
  }
};

const loadBodyTypes = async () => {
  try {
    const data = await fetchBodyTypeData();
    setBodyTypes(data);
  } catch (error) {
    console.error('Failed to load body types:', error);
  }
};

const loadComplexions = async () => {
  try {
    const data = await fetchComplexionData();
    setComplexions(data);
  } catch (error) {
    console.error('Failed to load complexions:', error);
  }
};

const loadCreatedbys = async () => {
  try {
    const data = await fetchCreatedbyData();
    setCreatedbys(data);
  } catch (error) {
    console.error('Failed to load created by options:', error);
  }
};

const loadEducations = async () => {
  try {
    const data = await fetchEducationData();
    setEducations(data);
  } catch (error) {
    console.error('Failed to load educations:', error);
  }
};

const loadEthnicitys = async () => {
  try {
    const data = await fetchEthnicityData();
    setEthnicitys(data);
  } catch (error) {
    console.error('Failed to load ethnicities:', error);
  }
};

const loadFoodHabits = async () => {
  try {
    const data = await fetchFoodHabitData();
    setFoodHabits(data);
  } catch (error) {
    console.error('Failed to load food habits:', error);
  }
};

const loadHightTypes = async () => {
  try {
    const data = await fetchHeightTypeData();
    setHightTypes(data);
  } catch (error) {
    console.error('Failed to load height types:', error);
  }
};

const loadMartialStatuss = async () => {
  try {
    const data = await fetchMartialStatusData();
    setMartialStatuss(data);
  } catch (error) {
    console.error('Failed to load marital statuses:', error);
  }
};

const loadMotherTonques = async () => {
  try {
    const data = await fetchMotherTongueData();
    setMotherTonques(data);
  } catch (error) {
    console.error('Failed to load mother tongues:', error);
  }
};

const loadRasis = async () => {
  try {
    const data = await fetchRasiData();
    setRasis(data);
  } catch (error) {
    console.error('Failed to load rasis:', error);
  }
};

const loadReligions = async () => {
  try {
    const data = await fetchReligionData();
    setReligions(data);
  } catch (error) {
    console.error('Failed to load religions:', error);
  }
};

const loadSexs = async () => {
  try {
    const data = await fetchSexData();
    setSexs(data);
  } catch (error) {
    console.error('Failed to load sexes:', error);
  }
};

const loadStars = async () => {
  try {
    const data = await fetchStarData();
    setStars(data);
  } catch (error) {
    console.error('Failed to load stars:', error);
  }
};

const loadWeightTypes = async () => {
  try {
    const data = await fetchWeightTypeData();
    setWeightTypes(data);
  } catch (error) {
    console.error('Failed to load weight types:', error);
  }
};

const loadCountrys = async () => {
  try {
    const data = await fetchCountryData();
    setCountrys(data);
  } catch (error) {
    console.error('Failed to load countries:', error);
  }
};
const loadProfessions = async () => {
  try {
    const data = await fetchProfessionData();
    setProfessions(data);
  } catch (error) {
    console.error('Failed to load professions:', error);
  }
};
  // Load other data similarly with error handling...
/*
  // Handle change functions
  const handleDOBChange = (event) => {
    const { value } = event.target;
    setProfile({
      ...profile,
      dob: value,
      dobFormatted: value,
    });
  };

  const handleTimeChange = (event) => {
    const { value } = event.target;
    setProfile({
      ...profile,
      astro_birthdaytime: profile.dobFormatted + 'T' + value,
      astroDobFormatted: value,
    });
  };
*/
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setProfile({
      ...profile,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSelectChange = (name) => (value) => {
    setProfile({ ...profile, [name]: value });
  };

  const validate = () => {
    const newErrors = {};

    if (!profile.name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!profile.createdby) {
      newErrors.createdby = 'Please select created by';
    }
    if (!profile.martialstatus) {
      newErrors.martialstatus = 'Please select marital status';
    }
 // Check if dob is provided
 if (!profile.dob) {
  newErrors.dob = 'Please select a date of birth';
} else {
  // Calculate the age
  const today = new Date();
  const birthDate = new Date(profile.dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the birth date hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  // Check if age is less than 18
  if (age < 18) {
    newErrors.dob = 'You must be at least 18 years old';
  }
}

    if (!profile.sex) {
      newErrors.sex = 'Gender is required';
    }
    if (!profile.citizencountry) {
      newErrors.citizencountry = 'Please select citizen country';
    }
    if (!profile.ethnicity) {
      newErrors.ethnicity = 'Please choose ethnicity';
    }
    if (!profile.motherTonque) {
      newErrors.motherTonque = 'Please choose mother tongue';
    }
    if (!profile.email.trim()) {
      newErrors.email = 'Please enter email';
    }
    if (!profile.contactname.trim()) {
      newErrors.contactname = 'Please enter contact name';
    }
    if (!profile.mobile.trim()) {
      newErrors.mobile = 'Please enter mobile number';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }
// Combine date and time
let astro_birthdaytime = null;
if (profile.timeOfBirth) {
  astro_birthdaytime = profile.dob + `T${profile.timeOfBirth}`;
}
const dataToSend = {
  ...profile,
  astro_birthdaytime, // Overwrite dob with combined date and time
};
    try {
      if (id) {
        await updateProfileCx(id, dataToSend, token);
      } else {
        await createProfileCx(dataToSend, token);
      }
      navigate(`/admin/profile/${id}`);
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };

  return (
    <div className="profile-form">
<h1>{id ? 'Admin Edit Profile' : 'Admin Create Profile'}</h1>

{/* Conditionally show the message when editing a profile */}
{id && (
  <p style={{ color: '#555', fontStyle: 'italic', marginTop: '10px' }}>
    Please note: You cannot change your gender or date of birth. If you need to make changes, you can remove the profile and create a new one, or <a href="/contact" style={{ color: '#007bff', textDecoration: 'underline' }}>contact us</a> for assistance.
  </p>
)}

      <form onSubmit={handleSubmit}>
        <h2>General Information</h2>

        <div className="form-group">
          <label>
            Created By:
            <CreatedbySelect
              value={profile.createdby}
              onChange={handleSelectChange('createdby')}
              options={createdbys}
            />
            {errors.createdby && <div className="error-message">{errors.createdby}</div>}
          </label>
        </div>

        <div className="form-group">
          <label>
            Nick Name:
            <input type="text" name="name" value={profile.name} onChange={handleChange} />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </label>
        </div>

        <div className="form-group">
          <label>
          Gender:
            <SexSelect
              value={profile.sex}
              onChange={handleSelectChange('sex')}
              options={sexs}
            />
            {errors.sex && <div className="error-message">{errors.sex}</div>}
          </label>
        </div>

        <div className="form-group">
          <label>
            Marital Status:
            <MartialStatusSelect
              value={profile.martialstatus}
              onChange={handleSelectChange('martialstatus')}
              options={martialstatuss}
            />
            {errors.martialstatus && <div className="error-message">{errors.martialstatus}</div>}
          </label>
        </div>

        <div className="form-group">
  <label>
    Date of Birth:
    <input
      type="date"
      name="dob"
      value={profile.dob}
      onChange={handleChange}
      required
    />
  </label>
  {errors.dob && <span className="error-message">{errors.dob}</span>}
</div>

        {/* Continue with other form fields, ensuring consistency and improved layout */}

       <div className="form-group">
          <label>
            Ethnicity: 
           <EthnicitySelect
              value={profile.ethnicity}
              onChange={handleSelectChange('ethnicity')}
              options={ethnicitys}
            />
            {errors.ethnicity && <div className="error-message">{errors.ethnicity}</div>}
            </label>
         </div>
       <div className="form-group">
          <label>
            Mother Tonque: 
           <MotherTonqueSelect
              value={profile.motherTonque}
              onChange={handleSelectChange('motherTonque')}
              options={mothertonques}
            />
            {errors.motherTonque && <div className="error-message">{errors.motherTonque}</div>}
            </label>
         </div>
       <div className="form-group">
          <label>
            Caste:<input type="text" name="additionalcaste" value={profile.additionalcaste} onChange={handleChange} />
          {errors.additionalcaste && <span className="error-message">{errors.additionalcaste}</span>}
</label></div>
       <div className="form-group">
          <label>
            Caste is not a barriar:<input type="checkbox" name="castenobar" checked={profile.castenobar} onChange={handleChange} />
          {errors.castenobar && <span className="error-message">{errors.castenobar}</span>}
</label></div>
       <div className="form-group">
          <label>
            Religion: 
           <ReligionSelect
              value={profile.religion}
              onChange={handleSelectChange('religion')}
              options={religions}
            />
            {errors.religion && <div className="error-message">{errors.religion}</div>}
            </label>
         </div>


       <div className="form-group">
          <label>
            Complexion: 
           <ComplexionSelect
              value={profile.complexion}
              onChange={handleSelectChange('complexion')}
              options={complexions}
            />
            {errors.complexion && <div className="error-message">{errors.complexion}</div>}
            </label>
         </div>
         <div className="form-group">
          <label>
            Body Type: 
           <BodyTypeSelect
              value={profile.bodytype}
              onChange={handleSelectChange('bodytype')}
              options={bodytypes}
            />
            {errors.bodytype && <div className="error-message">{errors.bodytype}</div>}
            </label>
         </div>

       <div className="form-group">
          <label>
            Food Habits: 
           <FoodHabitSelect
              value={profile.foodhabits}
              onChange={handleSelectChange('foodhabits')}
              options={foodhabits}
            />
            {errors.foodhabits && <div className="error-message">{errors.foodhabits}</div>}
            </label>
         </div>

       <div className="form-group">
          <label>
            Education: 
           <EducationSelect
              value={profile.education}
              onChange={handleSelectChange('education')}
              options={educations}
            />
            {errors.education && <div className="error-message">{errors.education}</div>}
            </label>
         </div>
         <div className="form-group">
          <label>
            Additional Education:<input type="text" name="additionalqualification" value={profile.additionalqualification} onChange={handleChange} />
          {errors.additionalqualification && <span className="error-message">{errors.additionalqualification}</span>}
</label></div>


       <div className="form-group">
       <label>
       Occupation:        
       <ProfessionSelect
              value={profile.occupation}
              onChange={handleSelectChange('occupation')}
              options={professions}
            />
            {errors.occupation && <div className="error-message">{errors.occupation}</div>}
            </label>
</div>
       <div className="form-group">
          <label>
            Additional Occupation Details:<input type="text" name="additionaloccupation" value={profile.additionaloccupation} onChange={handleChange} />
          {errors.additionaloccupation && <span className="error-message">{errors.additionaloccupation}</span>}
</label></div>

       <div>
          <label>
            Living City:<input type="text" name="livingcity" value={profile.livingcity} onChange={handleChange} />
          {errors.livingcity && <span className="error-message">{errors.livingcity}</span>}
</label></div>

       <div className="form-group">
          <label>
            Living Country: 
           <CountrySelect
              value={profile.residentcountry}
              onChange={handleSelectChange('residentcountry')}
              options={countrys}
            />
            {errors.residentcountry && <div className="error-message">{errors.residentcountry}</div>}
            </label>
         </div>
       <div className="form-group">
          <label>
            Can Sponsor Spouse:<input type="checkbox" name="sponsor" checked={profile.sponsor} onChange={handleChange} />
          {errors.sponsor && <span className="error-message">{errors.sponsor}</span>}
</label></div>
		 
       <div className="form-group">
          <label>
            Citizen Country: 
           <CountryMandatorySelect
              value={profile.citizencountry}
              onChange={handleSelectChange('citizencountry')}
              options={countrys}
            />
            {errors.citizencountry && <div className="error-message">{errors.citizencountry}</div>}
            </label>
         </div>
              <div className="form-group">
          <label>
            Birth City:<input type="text" name="birthcity" value={profile.birthcity} onChange={handleChange} />
          {errors.birthcity && <span className="error-message">{errors.birthcity}</span>}
</label></div>
              <div className="form-group">
          <label>
            Birth Country: 
           <CountrySelect
              value={profile.birthcountry}
              onChange={handleSelectChange('birthcountry')}
              options={countrys}
            />
            {errors.birthcountry && <div className="error-message">{errors.birthcountry}</div>}
            </label>
         </div>

 
      <div className="form-group-inline">
        <label htmlFor="weight">Weight: </label>
        <input
          type="text"
          id="weight"
          name="weight"
          value={profile.weight}
          onChange={handleChange}
        />
        {errors.weight && <span className="error-message">{errors.weight}</span>}

        <WeightTypeSelect
          value={profile.weightType}
          onChange={handleSelectChange('weightType')}
          options={weighttypes}
        />
        {errors.weightType && <div className="error-message">{errors.weightType}</div>}
      </div>

      <div className="form-group-inline">
        <label htmlFor="height">Height: </label>
        <input
          type="text"
          id="height"
          name="height"
          value={profile.height}
          onChange={handleChange}
        />
        {errors.height && <span className="error-message">{errors.height}</span>}

        <HightTypeSelect
          value={profile.heightType}
          onChange={handleSelectChange('heightType')}
          options={highttypes}
        />
        {errors.heightType && <div className="error-message">{errors.heightType}</div>}
      </div>
 	<div className="form-group">
          <label>
            Annualincome:<input type="text" name="annualincome" value={profile.annualincome} onChange={handleChange} />
          {errors.annualincome && <span className="error-message">{errors.annualincome}</span>}
</label></div>
              <div className="form-group">
          <label>
            Any Other Information :<textarea name="other" value={profile.other} onChange={handleChange}  rows="4" cols="60"  />
          {errors.other && <span className="error-message">{errors.other}</span>}
</label></div>
	     <h2>Your Expectations about the spouse</h2>

              <div className="form-group">
          <label>
            Your Expectations:<textarea name="ex_other" value={profile.ex_other} onChange={handleChange} rows="4" cols="60" />
          {errors.ex_other && <span className="error-message">{errors.ex_other}</span>}
</label></div>
	     <h2>Astro Details</h2>
              <div className="form-group">
          <label>
            Notbeleiveinhor:<input type="checkbox" name="notbeleiveinhor" checked={profile.notbeleiveinhor} onChange={handleChange} />
          {errors.notbeleiveinhor && <span className="error-message">{errors.notbeleiveinhor}</span>}
</label></div>

              <div className="form-group">
          <label>
            Astro star: 
           <StarSelect
              value={profile.astro_star}
              onChange={handleSelectChange('astro_star')}
              options={stars}
            />
            {errors.astro_star && <div className="error-message">{errors.astro_star}</div>}
            </label>
         </div>
              <div className="form-group">
          <label>
            Astro rasi: 
           <RasiSelect
              value={profile.astro_rasi}
              onChange={handleSelectChange('astro_rasi')}
              options={rasis}
            />
            {errors.astro_rasi && <div className="error-message">{errors.astro_rasi}</div>}
            </label>
         </div>
              <div className="form-group">
          <label>
            Astro padam:<input type="number" name="astro_padam" value={profile.astro_padam} onChange={handleChange} />
          {errors.astro_padam && <span className="error-message">{errors.astro_padam}</span>}
</label></div>
              <div className="form-group">
          <label>
            Astro pavam:<input type="text" name="astro_pavam" value={profile.astro_pavam} onChange={handleChange} />
          {errors.astro_pavam && <span className="error-message">{errors.astro_pavam}</span>}
</label></div>
<div className="form-group">
  <label>
    Time of Birth (Optional):
    <input
      type="time"
      name="timeOfBirth"
      value={profile.timeOfBirth || ''}
      onChange={handleChange}
    />
  </label>
</div>
              <div className="form-group">
          <label>
            Astro  Chevai Thosam:<input type="checkbox" name="astro_ChevaiThosam" checked={profile.astro_ChevaiThosam} onChange={handleChange} />
          {errors.astro_ChevaiThosam && <span className="error-message">{errors.astro_ChevaiThosam}</span>}
</label></div>

        {/* Contact Information */}
        <h2>Contact Information</h2>

        <div className="form-group">
          <label>
            Email:
            <input type="email" name="email" value={profile.email} onChange={handleChange} />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </label>
        </div>

        <div className="form-group-inline">
          <label>
            Contact Name:
            <TitleSelect
              value={profile.title}
              onChange={handleSelectChange('title')}
              options={titles}
            />
            {errors.title && <div className="error-message">{errors.title}</div>}
          </label>
          <label>
            <input
              type="text"
              name="contactname"
              value={profile.contactname}
              onChange={handleChange}
              placeholder="Contact Name"
            />
            {errors.contactname && <div className="error-message">{errors.contactname}</div>}
          </label>
        </div>
                      <div className="form-group">
          <label>
            Organization:<input type="text" name="organization" value={profile.organization} onChange={handleChange} />
          {errors.organization && <span className="error-message">{errors.organization}</span>}
</label></div>
              <div className="form-group">
          <label>
            Address1:<input type="text" name="address1" value={profile.address1} onChange={handleChange} />
          {errors.address1 && <span className="error-message">{errors.address1}</span>}
</label></div>
              <div className="form-group">
          <label>
            Address2:<input type="text" name="address2" value={profile.address2} onChange={handleChange} />
          {errors.address2 && <span className="error-message">{errors.address2}</span>}
</label></div>
              <div className="form-group">
          <label>
            Address3:<input type="text" name="address3" value={profile.address3} onChange={handleChange} />
          {errors.address3 && <span className="error-message">{errors.address3}</span>}
</label></div>
              <div className="form-group">
          <label>
            City:<input type="text" name="city" value={profile.city} onChange={handleChange} />
          {errors.city && <span className="error-message">{errors.city}</span>}
</label></div>
              <div className="form-group">
          <label>
            Postcode:<input type="text" name="postcode" value={profile.postcode} onChange={handleChange} />
          {errors.postcode && <span className="error-message">{errors.postcode}</span>}
</label></div>
<div className="form-group">
          <label>
            Country: 
           <CountrySelect
              value={profile.country}
              onChange={handleSelectChange('country')}
              options={countrys}
            />
            {errors.country && <div className="error-message">{errors.country}</div>}
            </label>
         </div>

              <div className="form-group">
          <label>
            Phone1:<input type="text" name="phone1" value={profile.phone1} onChange={handleChange} />
          {errors.phone1 && <span className="error-message">{errors.phone1}</span>}
</label></div>
              <div className="form-group">
          <label>
            Phone2:<input type="text" name="phone2" value={profile.phone2} onChange={handleChange} />
          {errors.phone2 && <span className="error-message">{errors.phone2}</span>}
</label></div>
              <div className="form-group">
          <label>
            Fax:<input type="text" name="fax" value={profile.fax} onChange={handleChange} />
          {errors.fax && <span className="error-message">{errors.fax}</span>}
</label></div>
              <div className="form-group">
          <label>
            Mobile:<input type="text" name="mobile" value={profile.mobile} onChange={handleChange} />
          {errors.mobile && <span className="error-message">{errors.mobile}</span>}
</label></div>

       <div className="form-group">
          <label>
            Profileid:<input type="number" name="profileid" value={profile.profileid} onChange={handleChange} />
          {errors.profileid && <span className="error-message">{errors.profileid}</span>}
</label></div>

        {/* Continue with other sections like Astro Details, Expectations, etc. */}

        <button type="submit">Save</button>
        <button type="button" onClick={() => navigate(`/admin/profile/${id}`)}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default ProfileForm;
