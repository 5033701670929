import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import { API_IDENTITY_URL } from '../lib/constants';
import { API_IDENTITY_URL } from '../common/AppControl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../contents/profile/AuthContext';
import { useNavigate } from 'react-router-dom';
import './LoginSignuppopup.css'; // Import your CSS file here
import axios from 'axios'; // Using axios for better error handling
//import { useForm } from 'react-hook-form';
//import { yupResolver } from '@hookform/resolvers/yup';
//import * as Yup from 'yup';


const LoginSignuppopup = () => {
  const [activeTab, setActiveTab] = useState('login');
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [registerData, setRegisterData] = useState({ email: '', password: '', confirmPassword: '' });
  const [resetData, setResetData] = useState({ email: '', otp: '', newPassword: '' });
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);

  // Added state variables for registration OTP
  const [otpSentForRegister, setOtpSentForRegister] = useState(false);
  const [registerOtp, setRegisterOtp] = useState('');
  const [recaptchaReady, setRecaptchaReady] = useState(false);

 // Wait for grecaptcha to be available
  useEffect(() => {
    const waitForGrecaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => setRecaptchaReady(true));
      } else {
        // Retry after a short delay if grecaptcha is not available yet
        setTimeout(waitForGrecaptcha, 500);
      }
    };
    waitForGrecaptcha();
  }, []);

  // Clear form data and errors when switching tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setLoginData({ email: '', password: '' });
    setRegisterData({ email: '', password: '', confirmPassword: '' });
    setResetData({ email: '', otp: '', newPassword: '' });
    setPasswordError('');
    setOtpSent(false); // Reset OTP sent flags
    setOtpSentForRegister(false);
  };

  // Login handlers remain the same...
  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
    const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
    // Check if the token is valid before making the request
    if (!token1) {
      toast.error('reCAPTCHA failed. Please try again.');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${API_IDENTITY_URL}login`, {
        domain: window.location.hostname,
        email: loginData.email,
        password: loginData.password,
        rememberMe: true,
        returnUrl: '',
        captchaToken: token1, // Pass the reCAPTCHA token
      });

      const data = response.data;
      if (data.returnCode === 1) {
        login(data.token, data.cxName);
        toast.success('Login successful');
        navigate('/dashboard');
        setAuthTokenInCookie(data.token, 7);
      } else {
        toast.error(data.returnText || 'Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in');
    } finally {
      setLoading(false);
    }
  };

  // Register handlers
  const handleRegisterInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePasswordConfirmation = (e) => {
    const confirmPassword = e.target.value;
    setRegisterData((prevData) => ({ ...prevData, confirmPassword }));
    setPasswordError(confirmPassword !== registerData.password ? 'Passwords do not match' : '');
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
    const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
    // Check if the token is valid before making the request
    if (!token1) {
      toast.error('reCAPTCHA failed. Please try again.');
      return;
    }    
    if (registerData.password !== registerData.confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${API_IDENTITY_URL}createotp`, {
        domain: window.location.hostname,
        email: registerData.email,
        password: registerData.password,
        captchaToken: token1, // Pass the reCAPTCHA token
      });

      const data = response.data;
      if (data.returnCode === 1) {
        // OTP sent, move to OTP input step
        setOtpSentForRegister(true);
        toast.success('OTP sent to your email');
      } else {
        toast.error(data.returnText || 'Registration failed');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error('Error creating user');
    } finally {
      setLoading(false);
    }
  };

  // New function to handle OTP submission for registration
  const handleRegisterOtpSubmit = async (event) => {
    event.preventDefault();
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
    const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
    // Check if the token is valid before making the request
    if (!token1) {
      toast.error('reCAPTCHA failed. Please try again.');
      return;
    } 
    setLoading(true);
    try {
      const response = await axios.post(`${API_IDENTITY_URL}createviaotp`, {
        email: registerData.email,
        password: registerData.password,
        otp: registerOtp,
        captchaToken: token1, // Pass the reCAPTCHA token
      });

      const data = response.data;
      if (data.returnCode === 1) {
        toast.success('Registration successful');
        // Optionally, log the user in automatically
        handleTabChange('login');
        setOtpSentForRegister(false);
      } else {
        toast.error(data.returnText || 'OTP verification failed');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Error verifying OTP');
    } finally {
      setLoading(false);
    }
  };

  // Reset password handlers remain the same...
  const handleResetInputChange = (e) => {
    const { name, value } = e.target;
    setResetData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleResetRequest = async (event) => {
    event.preventDefault();
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
    const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
    // Check if the token is valid before making the request
    if (!token1) {
      toast.error('reCAPTCHA failed. Please try again.');
      return;
    } 
    setLoading(true);
    try {
      const response = await axios.post(`${API_IDENTITY_URL}otpresetpassword`, {
        email: resetData.email,
        captchaToken: token1, // Pass the reCAPTCHA token
     });

      const data = response.data;
      if (data.returnCode === 1) {
        setOtpSent(true);
        toast.success('OTP sent to your email');
      } else {
        setOtpSent(false);
        toast.error(data.returnText || 'Failed to send OTP');
      }
    } catch (error) {
      setOtpSent(false);
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyAndReset = async (e) => {
    e.preventDefault();
    if (!recaptchaReady) {
      toast.error('reCAPTCHA is not ready yet. Please try again.');
      return;
    }
    const token1 = await window.grecaptcha.execute('6LeRLE8qAAAAAMPEwQGhBp8BhB3HaekRAF3-Db5m', { action: 'submit' });
    // Check if the token is valid before making the request
    if (!token1) {
      toast.error('reCAPTCHA failed. Please try again.');
      return;
    } 
    setLoading(true);
    try {
      const response = await axios.post(`${API_IDENTITY_URL}resetpasswordbyotp`, {
        email: resetData.email,
        otp: resetData.otp,
        newPassword: resetData.newPassword,
        captchaToken: token1, // Pass the reCAPTCHA token
      });

      const data = response.data;
      if (data.returnCode === 1) {
        setOtpSent(false);
        toast.success('Password reset successfully');
        handleTabChange('login');
      } else {
        toast.error(data.returnText || 'Failed to reset password');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Failed to reset password');
    } finally {
      setLoading(false);
    }
  };

  const setAuthTokenInCookie = (token, daysToExpire) => {
    const expires = new Date(Date.now() + daysToExpire * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `authToken=${token}; expires=${expires}; path=/; Secure; SameSite=Strict`;
  };

  return (
    <div className="mt-5">
      <Popup
        trigger={
          <button className="btn btn-link text-secondary">
            <i className="bi bi-person"></i> Login/Register
          </button>
        }
        modal
        closeOnDocumentClick
      >
        {(close) => (
          <div className="modal-content text-center">
            <div className="modal-header">
              <ul className="nav nav-tabs justify-content-center">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'login' ? 'active' : ''}`}
                    onClick={() => handleTabChange('login')}
                  >
                    Login
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'register' ? 'active' : ''}`}
                    onClick={() => handleTabChange('register')}
                  >
                    Register
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'reset' ? 'active' : ''}`}
                    onClick={() => handleTabChange('reset')}
                  >
                    Reset Password
                  </button>
                </li>
              </ul>
            </div>
            <div className="modal-body justify-content-center">
              {/* Login Tab */}
              {activeTab === 'login' && (
                <form onSubmit={handleLogin}>
                  <div className="input-with-icon-left">
                    <i className="bi bi-envelope"></i>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Email Address"
                      name="email"
                      value={loginData.email}
                      onChange={handleLoginInputChange}
                      required
                    />
                  </div>
                  <div className="input-with-icon-left">
                    <i className="bi bi-lock"></i>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Your Password"
                      name="password"
                      value={loginData.password}
                      onChange={handleLoginInputChange}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="button full-width"
                    disabled={loading}
                  >
                    {loading ? 'Logging in...' : 'Login'}
                  </button>
                  <p
                    className="text-primary mt-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTabChange('reset')}
                  >
                    Forgot Your Password?
                  </p>
                </form>
              )}

              {/* Register Tab */}
              {activeTab === 'register' && (
                <div>
                  {!otpSentForRegister ? (
                    <form onSubmit={handleRegister}>
                      <div className="input-with-icon-left">
                        <i className="bi bi-envelope"></i>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          name="email"
                          value={registerData.email}
                          onChange={handleRegisterInputChange}
                          required
                        />
                      </div>
                      <div className="input-with-icon-left">
                        <i className="bi bi-lock"></i>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter a Valid Password"
                          name="password"
                          value={registerData.password}
                          onChange={handleRegisterInputChange}
                          required
                        />
                      </div>
                      <div className="input-with-icon-left">
                        <i className="bi bi-lock"></i>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Retype Your Password"
                          name="confirmPassword"
                          value={registerData.confirmPassword}
                          onChange={handlePasswordConfirmation}
                          required
                        />
                        {passwordError && (
                          <span style={{ color: 'red', fontSize: '12px' }}>{passwordError}</span>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="button full-width"
                        disabled={loading}
                      >
                        {loading ? 'Registering...' : 'Register'}
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={handleRegisterOtpSubmit}>
                      <div className="input-with-icon-left">
                        <i className="bi bi-shield-lock"></i>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter OTP"
                          name="otp"
                          value={registerOtp}
                          onChange={(e) => setRegisterOtp(e.target.value)}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="button full-width"
                        disabled={loading}
                      >
                        {loading ? 'Verifying OTP...' : 'Verify & Complete Registration'}
                      </button>
                      <p
                        className="text-primary mt-3"
                        style={{ cursor: 'pointer' }}
                        onClick={handleRegister}
                      >
                        Resend OTP
                      </p>
                    </form>
                  )}
                </div>
              )}

              {/* Reset Password Tab remains the same */}
              {activeTab === 'reset' && (
                <div>
                  {!otpSent && (
                    <form onSubmit={handleResetRequest}>
                      <div className="input-with-icon-left">
                        <i className="bi bi-envelope"></i>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          name="email"
                          value={resetData.email}
                          onChange={handleResetInputChange}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="button full-width"
                        disabled={loading}
                      >
                        {loading ? 'Sending OTP...' : 'Request OTP'}
                      </button>
                    </form>
                  )}
                  {otpSent && (
                    <form onSubmit={handleVerifyAndReset}>
                      <div className="input-with-icon-left">
                        <i className="bi bi-shield-lock"></i>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter OTP"
                          name="otp"
                          value={resetData.otp}
                          onChange={handleResetInputChange}
                          required
                        />
                      </div>
                      <div className="input-with-icon-left">
                        <i className="bi bi-lock"></i>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Your New Password"
                          name="newPassword"
                          value={resetData.newPassword}
                          onChange={handleResetInputChange}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="button full-width"
                        disabled={loading}
                      >
                        {loading ? 'Resetting Password...' : 'Reset Password'}
                      </button>
                    </form>
                  )}
                </div>
              )}
            </div>
            <ToastContainer />
          </div>
        )}
      </Popup>
    </div>
  );
};

export default LoginSignuppopup;
