import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Footer() {
  return (
    <footer className="bg-dark text-light py-5">
      <div className="container">
        <div className="row">

          {/* Company Info */}
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase">About Us</h5>
            <p>
              This matrimonial portal is developed and operated as a free service by 
              <a href="https://www.cventures.lk" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none ms-1">
                Confluence Ventures (Pvt) Ltd
              </a>.
            </p>
            <div className="socials mt-3">
              <a href="/" className="text-light me-3">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="/" className="text-light me-3">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="/" className="text-light me-3">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/company/confluenceventures" target="_blank" rel="noopener noreferrer" className="text-light">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-light text-decoration-none">Home</Link>
              </li>
              <li>
                <Link to="/about" className="text-light text-decoration-none">About Us</Link>
              </li>
              <li>
                <Link to="/profile/search" className="text-light text-decoration-none">Search Profiles</Link>
              </li>
              <li>
                <Link to="/contact" className="text-light text-decoration-none">Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* Terms and Privacy */}
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase">Legal</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/terms" className="text-light text-decoration-none">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy" className="text-light text-decoration-none">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} matrimony.lk | Designed & Developed by 
              <a href="https://www.cventures.lk" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none ms-1">
                Confluence Ventures (Pvt) Ltd
              </a>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
