import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from  './profile/AuthContext.js';
import { API_URL } from '../common/AppControl.js';
import {GetHeaders} from '../User/Service/ProfileCx.js'
const MyRequests = () => {
  const {token}=useAuth();
  const [permissions, setPermissions] = useState([]);
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // Fixed page size
  const [totalCount, setTotalCount] = useState(0);

  // Fetch data from the API
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(`${API_URL}pp/myrequests?page=${page}&pageSize=${pageSize}`,
          {
            headers: GetHeaders(true,token), // auth required, change it to false if it does not
          });
        setPermissions(response.data.items);
        setFilteredPermissions(response.data.items);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.error("Error fetching permissions", error);
      }
    };

    fetchPermissions();
  }, [page]);

  // Handle search and filter
  useEffect(() => {
    let updatedPermissions = [...permissions];

    // Filter by search text
    if (searchText) {
      updatedPermissions = updatedPermissions.filter(
        (item) =>
          item.requesterName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.receiverName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filter by status
    if (statusFilter !== '') {
      updatedPermissions = updatedPermissions.filter(
        (item) => item.isApproved === parseInt(statusFilter)
      );
    }

    setFilteredPermissions(updatedPermissions);
  }, [searchText, statusFilter, permissions]);

  // Handle pagination
  const handleNextPage = () => {
    if (page * pageSize < totalCount) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  // Render the status
  const renderStatus = (isApproved,reason) => {
    switch (isApproved) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Rejected:"+reason||'Not Given';
      default:
        return "Unknown";
    }
  };

  return (
    <div>
      <h1>My Requests</h1>

      {/* Search and Filter Section */}
      <div>
        <input
          type="text"
          placeholder="Search by Requester or Receiver"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="">All Status</option>
          <option value="0">Pending</option>
          <option value="1">Approved</option>
          <option value="2">Rejected</option>
        </select>
      </div>

      {/* Data Table */}
      <table>
        <thead>
          <tr>
            <th>My Profile</th>
            <th>Receiver Profile</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredPermissions.map((item,index) => (
            <tr key={item.id || index}>
              <td>
                <a href={`/profile/${item.requestProfileId}`} target="_blank" rel="noopener noreferrer">
                  {item.requesterName}
                </a>
              </td>
              <td>
                <a href={`/profile/${item.receiverProfileId}`} target="_blank" rel="noopener noreferrer">
                  {item.receiverName}
                </a>
              </td>
              <td>{renderStatus(item.isApproved,item.reason)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div>
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <span> Page {page} </span>
        <button onClick={handleNextPage} disabled={page * pageSize >= totalCount}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MyRequests;
