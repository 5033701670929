import React, { Component } from 'react';

class Page extends Component {
  // When the component mounts, update the meta tags
  componentDidMount() {
    this.updateMetaTags();
  }

  // When the component receives new props, update the meta tags if necessary
  componentDidUpdate(prevProps) {
    if (
      prevProps.title !== this.props.title ||
      prevProps.description !== this.props.description ||
      prevProps.keywords !== this.props.keywords
    ) {
      this.updateMetaTags();
    }
  }

  // Method to update the document title and meta tags
  updateMetaTags() {
    const { title, description, keywords } = this.props;

    // Update the document title
    document.title = title;

    // Update the description meta tag
    this.updateOrCreateMetaTag('description', description);

    // Update the keywords meta tag
    this.updateOrCreateMetaTag('keywords', keywords);
  }

  // Helper method to either update an existing meta tag or create a new one
  updateOrCreateMetaTag(name, content) {
    let tag = document.head.querySelector(`meta[name="${name}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute('name', name);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  }

  render() {
    return (
        <></>
    );
  }
}

export default Page;
