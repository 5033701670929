// LatestProfiles.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { API_URL } from '../common/config';
import { API_URL } from '../common/AppControl';
import './LatestProfiles.css'; // Ensure this CSS file exists

const LatestProfiles = ({ sx }) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const gender = sx === 1 ? 'Male' : 'Female';

  useEffect(() => {
    const fetchLatestProfiles = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${API_URL}pp/latest`, {
          params: { sx },
        });

        if (response.data && response.data.items) {
          setProfiles(response.data.items);
        } else {
          setProfiles([]);
        }
      } catch (err) {
        setError('Error fetching profiles');
      } finally {
        setLoading(false);
      }
    };

    fetchLatestProfiles();
  }, [sx]);

  return (
    <div className={`latest-profiles-container ${gender.toLowerCase()}`}>
      <h2>Latest {gender} Profiles</h2>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {!loading && !error && profiles.length === 0 && <p>No profiles found.</p>}

      {!loading && !error && profiles.length > 0 && (
        <ul>
          {profiles.map((profile, index) => {
            const searchResults = JSON.parse(profile.searchResults);
           // const profileId = profile.profileid || profile.id || 'N/A';
            //const profileURL = profile.profileURL || `/profile/${profileId}`;

            return (
              <li key={index}>
                {/* Display Profile ID at the top */}
                <div className="profile-header">
                  <strong>Profile ID:</strong> {searchResults.profileid}
                </div>

                {/* Profile Details */}
                <div className="profile-details">
                  <strong>Name:</strong> {searchResults.name} <br />
                  <strong>Age:</strong> {searchResults.age} <br />
                  <strong>Occupation:</strong> {searchResults.occupation || 'Not provided'} <br />
                  <strong>City:</strong> {searchResults.livingcity || 'Not provided'} <br />
                  <strong>Religion:</strong> {searchResults.religion || 'Not provided'} <br />
                  <strong>Ethnicity:</strong> {searchResults.ethnicity || 'Not provided'} <br />
                  <strong>Education:</strong> {searchResults.education || 'Not provided'}
                </div>

                {/* Hyperlink to Profile URL at the bottom */}
                <div className="profile-footer">
                  <a target='_new' href={`/profile/${searchResults.profileURL}`} >View</a>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LatestProfiles;
