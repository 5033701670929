import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
//Admin functions
import ProfileListAdmin from './Admin/ProfileListAdmin';
import ProfileFormAdmin from './Admin/ProfileFormAdmin';
import ProfileDetailAdmin from './Admin/ProfileDetailAdmin';
//import ProfileFormAdmin from './Admin/ProfileFormAdmin';



import ProfileList from './User/ProfileList';
import ProfileDetail from './User/ProfileDetail';
import ProfileForm from './User/ProfileForm';
import FullPageDetails from './components/FullPageDetails';
import PageDetails from './components/PageDetails';
import Header from './components/Header';
import Footer from './components/Footer';
import Aboutus from './contents/AboutUs';
import Home from './contents/Home';
import PrivacyPolicy from './contents/PrivacyPolicy';
import TermsAndConditions from './contents/TermsAndConditions';
import Contactus from './contents/ContactUs';
import FAQPage from './contents/FAQPage';
import NotFound from './contents/NotFound';
import MyRequests from './contents/MyRequests';
//import Resend from './contents/resend';
//import EmailVerification from './contents/Verification';
import { AuthProvider } from './contents/profile/AuthContext';
import Login from './contents/profile/Login';
//import Dashboard from './contents/profile/Dashboard';
import ForgotPasswordForm from './contents/ForgotPasswordForm';
import ChangeEmailComponent from './contents/ChangeEmail';
import 'reactjs-popup/dist/index.css';
////import Register from './contents/RegisterAcc';
import ChangePassword from './contents/ChangePassword';
import UpdateUserForm from './contents/UpdateUserForm';
import ScrollToTop from './components/ScrollToTop';
import ProfileSearchContainer from './contents/ProfileSearchContainer';
import ProfilePage from './contents/ProfilePage';
import MyApprovals from './contents/MyApprovals';

function App() {

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <AuthProvider>
        <Header />
        {/* Optional: Add a button to toggle theme */}
        {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
        <Routes>
          {/* Home Page */}
          <Route
            path="/"
            element={
              <PageDetails pageName="homepage">
                <Home />
              </PageDetails>
            }
          />
          <Route
            path="/profile/search"
            element={
              <PageDetails pageName="search">
                <ProfileSearchContainer />
              </PageDetails>
            }
          />
          <Route
            path="/profile/:profileSlug"
            element={
              <PageDetails pageName="profile">
                <ProfilePage />
              </PageDetails>
            }
          />
          <Route
            path="/contact"
            element={
              <PageDetails pageName="contact">
                <Contactus />
              </PageDetails>
            }
          />
          <Route
            path="/faq"
            element={
              <PageDetails pageName="faq">
                <FAQPage />
              </PageDetails>
            }
          />

          <Route
            path="/MyRequests"
            element={
              <FullPageDetails pageName="loggedIn">
                <MyRequests />
              </FullPageDetails>
            }
          />          
          <Route
            path="/MyApprovals"
            element={
              <FullPageDetails pageName="loggedIn">
                <MyApprovals />
              </FullPageDetails>
            }
          />          
          
          <Route
            path="/privacy"
            element={
              <PageDetails pageName="privacy">
                <PrivacyPolicy />
              </PageDetails>
            }
          />

          <Route
            path="/about"
            element={
              <PageDetails pageName="about">
                <Aboutus />
              </PageDetails>
            }
          />
          <Route
            path="/terms"
            element={
              <PageDetails pageName="terms">
                <TermsAndConditions />
              </PageDetails>
            }
          />
          <Route
            path="/grooms"
            element={
              <PageDetails pageName="allbridegrooms">
                <ProfileSearchContainer sex="1" />
              </PageDetails>
            }
          />
          <Route
            path="/brides"
            element={
              <PageDetails pageName="allbridegrooms">
                    <ProfileSearchContainer sex="2" />
              </PageDetails>
            }
          />
          {/* Adjusted Route with Hyphens */}
          <Route
            path="/Matrimonial-Marital-Status/:ms/:statusName/:gender"
            element={
              <PageDetails pageName="MaritalStatus">
                <ProfileSearchContainer />
              </PageDetails>
            }
          />
          {/* Additional Routes */}
          <Route
            path="/Matrimonial-Ethnicity/:ec/:ethnicityName/:gender"
            element={
              <PageDetails pageName="Ethnicity">
                <ProfileSearchContainer />
              </PageDetails>
            }
          />
          <Route
            path="/dashboard"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileList />
              </FullPageDetails>
            }
          />
          <Route
            path="/profiles"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileList />
              </FullPageDetails>
            }
          />
          <Route
            path="/profiles/new"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileForm />
              </FullPageDetails>
            }
          />
                    <Route
            path="/profiles/edit/:id"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileForm />
              </FullPageDetails>
            }
          />

          <Route
            path="/profiles/:id"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileDetail />
              </FullPageDetails>
            }
          />
          <Route
            path="/changeemail"
            element={
              <PageDetails pageName="changeemail">
                <ChangeEmailComponent />
              </PageDetails>
            }
          />
                    <Route
            path="/changepassword"
            element={
              <PageDetails pageName="LoggedIn">
                <ChangePassword />
              </PageDetails>
            }
          />
          
                    <Route
            path="/updatedetails"
            element={
              <PageDetails pageName="updatedetails">
                <UpdateUserForm />
              </PageDetails>
            }
          />
          
          <Route
            path="/reset"
            element={
              <PageDetails pageName="reset">
                <ForgotPasswordForm />
              </PageDetails>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin/profile"
            element={
              <FullPageDetails pageName="loggedIn">
          <ProfileListAdmin/>
          </FullPageDetails>
            }
          />
                    <Route
            path="/profile/admin/edit/:id"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileFormAdmin />
              </FullPageDetails>
            }
          />
          <Route
            path="/admin/profile/:id"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileDetailAdmin />
              </FullPageDetails>
            }
          />
          <Route
            path="/admin/profile/new"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileFormAdmin />
              </FullPageDetails>
            }
          />
                    <Route
            path="/admin/profile/edit/:id"
            element={
              <FullPageDetails pageName="loggedIn">
                <ProfileFormAdmin />
              </FullPageDetails>
            }
          />

  {/* Make sure to place the wildcard route at the bottom */}
  <Route
    path="*"
    element={
      <PageDetails pageName="notfound">
        <NotFound />
      </PageDetails>
    }
  />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
