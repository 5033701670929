// ProfileSearch.js
import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Dropdown from '../components/Dropdown';
import { dropdownData } from '../data/dropdownData';

const ProfileSearch = ({ onSearch, initialValues }) => {
  const [formData, setFormData] = useState(initialValues);

  // Handle numeric input validation for Age fields
  const handleAgeInput = (e) => {
    const { name, value } = e.target;
    if (/^\d{0,2}$/.test(value)) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="mb-4">Profile Search</h2>

      <Row>
        <Col md={6}>
          <Dropdown
            label="Looking for"
            id="sx"
            name="sx"
            value={formData.sx}
            options={dropdownData.sex}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Dropdown
            label="Marital Status"
            id="ms"
            name="ms"
            value={formData.ms}
            options={dropdownData.maritalStatus}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Dropdown
            label="Religion"
            id="re"
            name="re"
            value={formData.re}
            options={dropdownData.religion}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Dropdown
            label="Ethnicity"
            id="ec"
            name="ec"
            value={formData.ec}
            options={dropdownData.ethnicity}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Dropdown
            label="Mother Tongue"
            id="mt"
            name="mt"
            value={formData.mt}
            options={dropdownData.motherTonque}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Dropdown
            label="Education"
            id="ed"
            name="ed"
            value={formData.ed}
            options={dropdownData.education}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Dropdown
            label="Occupation"
            id="oc"
            name="oc"
            value={formData.oc}
            options={dropdownData.occupation}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Dropdown
            label="Residing In"
            id="rc"
            name="rc"
            value={formData.rc}
            options={dropdownData.country}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col md={3}>
          <Form.Group controlId="af">
            <Form.Label>Age From</Form.Label>
            <Form.Control
              type="text"
              name="af"
              value={formData.af}
              maxLength="2"
              onChange={handleAgeInput}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="at">
            <Form.Label>Age To</Form.Label>
            <Form.Control
              type="text"
              name="at"
              value={formData.at}
              maxLength="2"
              onChange={handleAgeInput}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="st">
            <Form.Label>Caste Search</Form.Label>
            <Form.Control
              type="text"
              name="st"
              value={formData.st}
              maxLength="15"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit" className="mt-3">
        Search
      </Button>
    </Form>
  );
};

export default ProfileSearch;
