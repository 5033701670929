// ProfileShortcuts.js
import React from 'react';
import './ProfileShortcuts.css';
// Data for male profile shortcuts
// Data for male profile shortcuts
const maleProfileShortcuts = {
  title: 'All Males-Grooms',
  link: '/Grooms', // Change to React Router-compatible route
  categories: [
    {
      name: 'Ethnicity',
      options: [
        { name: 'Sinhalese', link: '/Matrimonial-Ethnicity/1/Sinhalese/Groom' },
        { name: 'Sri Lankan Tamil', link: '/Matrimonial-Ethnicity/2/Sri_Lankan_Tamil/Groom' },
        { name: 'Sri Lankan Moor/Muslim', link: '/Matrimonial-Ethnicity/3/Sri_Lankan_Moor_Muslim/Groom' },
        { name: 'Indian Tamil', link: '/Matrimonial-Ethnicity/4/Indian_Tamil/Groom' },
        { name: 'Sri Lankan Malay', link: '/Matrimonial-Ethnicity/5/Sri_Lankan_Malay/Groom' },
        { name: 'Burgher/Eurasian', link: '/Matrimonial-Ethnicity/6/Burgher_Eurasian/Groom' },
        { name: 'Indian Moor', link: '/Matrimonial-Ethnicity/7/Indian_Moor/Groom' },
        { name: 'Other', link: '/Matrimonial-Ethnicity/8/Other/Groom' },
      ],
    },
    {
      name: 'Mother Tongue',
      options: [
        { name: 'Sinhala', link: '/Matrimonial-Mother-Tongue/1/Sinhala/Groom' },
        { name: 'Tamil', link: '/Matrimonial-Mother-Tongue/2/Tamil/Groom' },
        { name: 'English', link: '/Matrimonial-Mother-Tongue/3/English/Groom' },
        { name: 'Malay', link: '/Matrimonial-Mother-Tongue/4/Malay/Groom' },
        { name: 'Other', link: '/Matrimonial-Mother-Tongue/5/Other/Groom' },
      ],
    },
    {
      name: 'Marital Status',
      options: [
        { name: 'Never Married', link: '/Matrimonial-Marital-Status/74/Never_Married/Groom' },
        { name: 'Widowed', link: '/Matrimonial-Marital-Status/75/Widowed/Groom' },
        { name: 'Divorced', link: '/Matrimonial-Marital-Status/76/Divorced/Groom' },
        { name: 'Separated', link: '/Matrimonial-Marital-Status/77/Separated/Groom' },
      ],
    },
    {
      name: 'Religion',
      options: [
        { name: 'Hindu', link: '/Matrimonial-Religion/65/Hindu/Groom' },
        { name: 'Christian', link: '/Matrimonial-Religion/66/Christian/Groom' },
        { name: 'Roman Catholic', link: '/Matrimonial-Religion/67/Roman_Catholic/Groom' },
        { name: 'Muslim', link: '/Matrimonial-Religion/190/Muslim/Groom' },
        { name: 'Buddhism', link: '/Matrimonial-Religion/296/Buddhist/Groom' },
      ],
    },
    {
      name: 'Occupation',
      options: [
        { name: 'Doctor', link: '/Matrimonial-Occupation/53/Doctor/Groom' },
        { name: 'Engineer', link: '/Matrimonial-Occupation/54/Engineer/Groom' },
        { name: 'Teacher', link: '/Matrimonial-Occupation/55/Teacher/Groom' },
        { name: 'Technician', link: '/Matrimonial-Occupation/56/Technician/Groom' },
        { name: 'Software Professional', link: '/Matrimonial-Occupation/78/Software_Professional/Groom' },
        { name: 'Sales Professional', link: '/Matrimonial-Occupation/79/Sales_Professional/Groom' },
        { name: 'Executive', link: '/Matrimonial-Occupation/80/Executive/Groom' },
        { name: 'Hardware Professional', link: '/Matrimonial-Occupation/81/Hardware_Professional/Groom' },
        { name: 'Accountant', link: '/Matrimonial-Occupation/82/Accountant/Groom' },
        { name: 'Consultant', link: '/Matrimonial-Occupation/83/Consultant/Groom' },
      ],
    },
    {
      name: 'Living in',
      options: [
        { name: 'Australia', link: '/Matrimonial-Country/14/Australia/Groom' },
        { name: 'Austria', link: '/Matrimonial-Country/13/Austria/Groom' },
        { name: 'Canada', link: '/Matrimonial-Country/38/Canada/Groom' },
        { name: 'Denmark', link: '/Matrimonial-Country/58/Denmark/Groom' },
        { name: 'Finland', link: '/Matrimonial-Country/69/Finland/Groom' },
        { name: 'France', link: '/Matrimonial-Country/74/France/Groom' },
        { name: 'Germany', link: '/Matrimonial-Country/56/Germany/Groom' },
        { name: 'Hong Kong', link: '/Matrimonial-Country/94/Hong_Kong/Groom' },
        { name: 'India', link: '/Matrimonial-Country/104/India/Groom' },
        { name: 'Italy', link: '/Matrimonial-Country/109/Italy/Groom' },
        { name: 'Japan', link: '/Matrimonial-Country/113/Japan/Groom' },
        { name: 'Kuwait', link: '/Matrimonial-Country/122/Kuwait/Groom' },
        { name: 'Malaysia', link: '/Matrimonial-Country/157/Malaysia/Groom' },
        { name: 'Norway', link: '/Matrimonial-Country/166/Norway/Groom' },
        { name: 'Qatar', link: '/Matrimonial-Country/186/Qatar/Groom' },
        { name: 'Russia', link: '/Matrimonial-Country/190/Russia/Groom' },
        { name: 'Saudi Arabia', link: '/Matrimonial-Country/192/Saudia_Arabia/Groom' },
        { name: 'Singapore', link: '/Matrimonial-Country/197/Singapore/Groom' },
        { name: 'South Africa', link: '/Matrimonial-Country/244/South_Africa/Groom' },
        { name: 'Spain', link: '/Matrimonial-Country/67/Spain/Groom' },
        { name: 'Sri Lanka', link: '/Matrimonial-Country/129/Sri_Lanka/Groom' },
        { name: 'Switzerland', link: '/Matrimonial-Country/210/Switzerland/Groom' },
        { name: 'Thailand', link: '/Matrimonial-Country/215/Thailand/Groom' },
        { name: 'U.A.E', link: '/Matrimonial-Country/2/U.A.E/Groom' },
        { name: 'U.K', link: '/Matrimonial-Country/76/U.K/Groom' },
        { name: 'U.S.A', link: '/Matrimonial-Country/230/U.S.A/Groom' },
        { name: 'New Zealand', link: '/Matrimonial-Country/170/New_Zealand/Groom' },
        { name: 'Mauritius', link: '/Matrimonial-Country/153/Mauritius/Groom' },
      ],
    }, // Missing curly brace for this category
  ], // Now properly closed
};
// Data for female profile shortcuts
const femaleProfileShortcuts = {
  title: 'All Female-Brides',
  link: '/Brides', // Change to React Router-compatible route
  categories: [
    {
      name: 'Ethnicity',
      options: [
        { name: 'Sinhalese', link: '/Matrimonial-Ethnicity/1/Sinhalese/Bride' },
        { name: 'Sri Lankan Tamil', link: '/Matrimonial-Ethnicity/2/Sri_Lankan_Tamil/Bride' },
        { name: 'Sri Lankan Moor/Muslim', link: '/Matrimonial-Ethnicity/3/Sri_Lankan_Moor_Muslim/Bride' },
        { name: 'Indian Tamil', link: '/Matrimonial-Ethnicity/4/Indian_Tamil/Bride' },
        { name: 'Sri Lankan Malay', link: '/Matrimonial-Ethnicity/5/Sri_Lankan_Malay/Bride' },
        { name: 'Burgher/Eurasian', link: '/Matrimonial-Ethnicity/6/Burgher_Eurasian/Bride' },
        { name: 'Indian Moor', link: '/Matrimonial-Ethnicity/7/Indian_Moor/Bride' },
        { name: 'Other', link: '/Matrimonial-Ethnicity/8/Other/Bride' },
      ],
    },
    {
      name: 'Mother Tongue',
      options: [
        { name: 'Sinhala', link: '/Matrimonial-Mother-Tongue/1/Sinhala/Bride' },
        { name: 'Tamil', link: '/Matrimonial-Mother-Tongue/2/Tamil/Bride' },
        { name: 'English', link: '/Matrimonial-Mother-Tongue/3/English/Bride' },
        { name: 'Malay', link: '/Matrimonial-Mother-Tongue/4/Malay/Bride' },
        { name: 'Other', link: '/Matrimonial-Mother-Tongue/5/Other/Bride' },
      ],
    },
    {
      name: 'Marital Status',
      options: [
        { name: 'Never Married', link: '/Matrimonial-Marital-Status/74/Never_Married/Bride' },
        { name: 'Widowed', link: '/Matrimonial-Marital-Status/75/Widowed/Bride' },
        { name: 'Divorced', link: '/Matrimonial-Marital-Status/76/Divorced/Bride' },
        { name: 'Separated', link: '/Matrimonial-Marital-Status/77/Separated/Bride' },
      ],
    },
    {
      name: 'Religion',
      options: [
        { name: 'Hindu', link: '/Matrimonial-Religion/65/Hindu/Bride' },
        { name: 'Christian', link: '/Matrimonial-Religion/66/Christian/Bride' },
        { name: 'Roman Catholic', link: '/Matrimonial-Religion/67/Roman_Catholic/Bride' },
        { name: 'Muslim', link: '/Matrimonial-Religion/190/Muslim/Bride' },
        { name: 'Buddhism', link: '/Matrimonial-Religion/296/Buddhist/Bride' },
      ],
    },
    {
      name: 'Occupation',
      options: [
        { name: 'Doctor', link: '/Matrimonial-Occupation/53/Doctor/Bride' },
        { name: 'Engineer', link: '/Matrimonial-Occupation/54/Engineer/Bride' },
        { name: 'Teacher', link: '/Matrimonial-Occupation/55/Teacher/Bride' },
        { name: 'Technician', link: '/Matrimonial-Occupation/56/Technician/Bride' },
        { name: 'Software Professional', link: '/Matrimonial-Occupation/78/Software_Professional/Bride' },
        { name: 'Sales Professional', link: '/Matrimonial-Occupation/79/Sales_Professional/Bride' },
        { name: 'Executive', link: '/Matrimonial-Occupation/80/Executive/Bride' },
        { name: 'Hardware Professional', link: '/Matrimonial-Occupation/81/Hardware_Professional/Bride' },
        { name: 'Accountant', link: '/Matrimonial-Occupation/82/Accountant/Bride' },
        { name: 'Consultant', link: '/Matrimonial-Occupation/83/Consultant/Bride' },
      ],
    },
    {
      name: 'Living in',
      options: [
        { name: 'Australia', link: '/Matrimonial-Country/14/Australia/Bride' },
        { name: 'Austria', link: '/Matrimonial-Country/13/Austria/Bride' },
        { name: 'Canada', link: '/Matrimonial-Country/38/Canada/Bride' },
        { name: 'Denmark', link: '/Matrimonial-Country/58/Denmark/Bride' },
        { name: 'Finland', link: '/Matrimonial-Country/69/Finland/Bride' },
        { name: 'France', link: '/Matrimonial-Country/74/France/Bride' },
        { name: 'Germany', link: '/Matrimonial-Country/56/Germany/Bride' },
        { name: 'Hong Kong', link: '/Matrimonial-Country/94/Hong_Kong/Bride' },
        { name: 'India', link: '/Matrimonial-Country/104/India/Bride' },
        { name: 'Italy', link: '/Matrimonial-Country/109/Italy/Bride' },
        { name: 'Japan', link: '/Matrimonial-Country/113/Japan/Bride' },
        { name: 'Kuwait', link: '/Matrimonial-Country/122/Kuwait/Bride' },
        { name: 'Malaysia', link: '/Matrimonial-Country/157/Malaysia/Bride' },
        { name: 'Norway', link: '/Matrimonial-Country/166/Norway/Bride' },
        { name: 'Qatar', link: '/Matrimonial-Country/186/Qatar/Bride' },
        { name: 'Russia', link: '/Matrimonial-Country/190/Russia/Bride' },
        { name: 'Saudi Arabia', link: '/Matrimonial-Country/192/Saudia_Arabia/Bride' },
        { name: 'Singapore', link: '/Matrimonial-Country/197/Singapore/Bride' },
        { name: 'South Africa', link: '/Matrimonial-Country/244/South_Africa/Bride' },
        { name: 'Spain', link: '/Matrimonial-Country/67/Spain/Bride' },
        { name: 'Sri Lanka', link: '/Matrimonial-Country/129/Sri_Lanka/Bride' },
        { name: 'Switzerland', link: '/Matrimonial-Country/210/Switzerland/Bride' },
        { name: 'Thailand', link: '/Matrimonial-Country/215/Thailand/Bride' },
        { name: 'U.A.E', link: '/Matrimonial-Country/2/U.A.E/Bride' },
        { name: 'U.K', link: '/Matrimonial-Country/76/U.K/Bride' },
        { name: 'U.S.A', link: '/Matrimonial-Country/230/U.S.A/Bride' },
        { name: 'New Zealand', link: '/Matrimonial-Country/170/New_Zealand/Bride' },
        { name: 'Mauritius', link: '/Matrimonial-Country/153/Mauritius/Bride' },
      ],
    },
	
	
    // Add other categories similarly...
  ],
};

const ProfileSection = ({ data, gender }) => (
  <div className={`profile-section ${gender}`}>
    <h2>{data.title}</h2>
    <a href={data.link} className="view-all-link">
      View All {gender === 'male' ? 'Grooms' : 'Brides'}
    </a>
    {data.categories.map((category, index) => (
      <div key={index} className="profile-category">
        <strong>{category.name}:</strong>
        <div className="options-list">
          {category.options.map((option, idx) => (
            <a href={option.link} key={idx} className="option-link">
              {option.name}
            </a>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const ProfileShortcuts = () => (
  <div className="profile-shortcuts">
    <ProfileSection data={maleProfileShortcuts} gender="male" />
    <ProfileSection data={femaleProfileShortcuts} gender="female" />
  </div>
);

export default ProfileShortcuts;
