import React, { useState } from 'react';
//import { API_IDENTITY_URL } from '../lib/constants';
import { API_IDENTITY_URL } from '../common/AppControl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './profile/AuthContext'; // Assuming you have an AuthContext to get the token

function ChangeEmail() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useAuth(); // Get the token if authentication is required

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_IDENTITY_URL}changeemail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Bearer ${token}` }), // Include token if available
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok && data.returnCode === 1) {
        toast.success('Email reset instructions sent to your email.');
        setEmail(''); // Clear the input field
      } else {
        toast.error(data.returnText || 'Failed to send reset instructions.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while sending reset instructions.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div id="reset" style={{ maxWidth: '400px', margin: '0 auto' }}>
      <h2>Change Email</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
            Email Address:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          {loading ? 'Sending...' : 'Send Link'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ChangeEmail;
