// Header.js
import React, { useState } from 'react';
import NavigationMenu from './NavigationMenu';
import { useAuth } from '../contents/profile/AuthContext';
import LoginSignuppopup from './LoginSignuppopup';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Header() {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="container-fluid">
      <div className="row align-items-center py-3">
        {/* Logo */}
        <div className="col-auto">
          <img src="/logo/logo.png" alt="Logo" className="img-fluid" />
        </div>

        {/* Navigation Menu */}
        <div className="col">
          <NavigationMenu />
        </div>

        {/* User Section */}
        <div className="col-auto">
          {user ? (
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle text-decoration-none"
                type="button"
                id="userDropdown"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
              >
                <i className="bi bi-person-circle"></i> {user}
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-end ${isOpen ? 'show' : ''}`}
                aria-labelledby="userDropdown"
              >
                <li>
                  <a className="dropdown-item" href="/dashboard">
                    My Profiles
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/myrequests">
                    My Requests
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/myapprovals">
                    My Approvals
                  </a>
                </li>                
                <li>
                  <a className="dropdown-item" href="/changepassword">
                    Change Password
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/updatedetails">
                    Update Details
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <LoginSignuppopup />
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
