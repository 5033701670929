// PageDetails.js
import React from 'react';
import pages from '../data/pages.json';
import Page from './updateMetaTags';
import './PageDetails.css'; // Make sure to update this CSS file

const FullPageDetails = ({ pageName, children }) => {
  const pageData = pages[pageName] || {};

  return (
    <div>
      {/* Update Meta Tags */}
      <Page
        title={pageData.title || 'Matrimony.lk'}
        description={pageData.description || 'Default Description'}
        keywords={pageData.keywords || 'Default Keywords'}
      />

      {/* Main Content without Left and Right Components */}
      <div className="container-fluid full-width-container">
        <div className="row justify-content-center">
          {/* Central Content */}
          <div className="col-md-12">
            <div id="pageelement">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageDetails;
