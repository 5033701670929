import axios from 'axios';
import { API_URL } from '../../../common/AppControl';

// Import your JSON files directly
import titleData from '../../../dropdowns/title.json';
import professionData from '../../../dropdowns/profession.json';
import bodyTypeData from '../../../dropdowns/bodytype.json';
import complexionData from '../../../dropdowns/complexion.json';
import countryData from '../../../dropdowns/country.json';
import createdbyData from '../../../dropdowns/createdby.json';
import educationData from '../../../dropdowns/education.json';
import ethnicityData from '../../../dropdowns/ethnicity.json';
import foodHabitData from '../../../dropdowns/foodhabit.json';
import heightTypeData from '../../../dropdowns/heighttype.json';
import martialStatusData from '../../../dropdowns/martialstatus.json';
import motherTongueData from '../../../dropdowns/mothertonque.json';
import rasiData from '../../../dropdowns/rasi.json';
import religionData from '../../../dropdowns/religion.json';
import sexData from '../../../dropdowns/sex.json';
import starData from '../../../dropdowns/star.json';
import weightTypeData from '../../../dropdowns/weightType.json';

const URL = `${API_URL}admin/profile`;

// Function to get headers based on whether the token is needed
export const GetHeaders = (requiresAuth, token) => {
  return requiresAuth
    ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' };
};

// Existing API functions remain the same
export const fetchProfilesCx = async (searchText, reviewedFilter, activeStatusFilter, page, pageSize, token) => {
  //const URL = 'https://localhost:7147/api/admin/profile';
  //console.log(`API Call: ${URL}`);
  const params = {
    searchText: searchText || '',
    page: page || 1,
    pageSize: pageSize || 10,
  };

  // Only include `reviewed` if it is not an empty string
  if (reviewedFilter !== '') {
    params.reviewed = reviewedFilter;
  }

  // Only include `active` if it is not an empty string
  if (activeStatusFilter !== '') {
    params.active = activeStatusFilter;
  }

  try {
    const response = await axios.get(URL, {
      params: params,
      headers: GetHeaders(true, token),
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching profiles:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchProfileByIdCx = async (id, token) => {
  const response = await axios.get(`${URL}/${id}`, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const createProfileCx = async (data, token) => {
  const response = await axios.post(URL, data, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const updateProfileCx = async (id, data, token) => {
  const response = await axios.put(`${URL}/${id}`, data, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const deleteProfileCx = async (id, token) => {
  const response = await axios.delete(`${URL}/${id}`, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};
export const deleteImageCx = async (id, profileid, token) => {
  const response = await axios.delete(`${API_URL}admin/images/${id}`, {
    headers: GetHeaders(true, token),
    params: {
      profileid: profileid, // Send profileid as a query parameter
    },
  });
  return response.data;
};

//`${API_URL}admin/profile`
export const publishProfileCx = async (id,ownerid, token) => {
  //alert(id);
  //alert(token);
  const response = await axios.put(
    `${API_URL}admin/publish/${id}`, 
    {}, // Empty body (second argument)
    {
      headers: GetHeaders(true, token), // Authorization headers
      params: {
        ownerid: ownerid, // Query parameter
      },
    }
  );
  
  return response.data;
};
// Updated functions to return imported dropdown data
export const fetchTitleData = () => {
  return titleData;
};

export const fetchProfessionData = () => {
  return professionData;
};

export const fetchBodyTypeData = () => {
  return bodyTypeData;
};

export const fetchComplexionData = () => {
  return complexionData;
};

export const fetchCountryData = () => {
  return countryData;
};

export const fetchCreatedbyData = () => {
  return createdbyData;
};

export const fetchEducationData = () => {
  return educationData;
};

export const fetchEthnicityData = () => {
  return ethnicityData;
};

export const fetchFoodHabitData = () => {
  return foodHabitData;
};

export const fetchHeightTypeData = () => {
  return heightTypeData;
};

export const fetchMartialStatusData = () => {
  return martialStatusData;
};

export const fetchMotherTongueData = () => {
  return motherTongueData;
};

export const fetchRasiData = () => {
  return rasiData;
};

export const fetchReligionData = () => {
  return religionData;
};

export const fetchSexData = () => {
  return sexData;
};

export const fetchStarData = () => {
  return starData;
};

export const fetchWeightTypeData = () => {
  return weightTypeData;
};
