import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy</h1>
      <p style={styles.paragraph}>
        Welcome to our matrimonial portal. We are committed to safeguarding your privacy and ensuring that your personal information is protected. This Privacy Policy outlines how we handle the information you provide to us and the rights you have regarding your data.
      </p>

      <h2 style={styles.subtitle}>Data Collection and Usage</h2>
      <p style={styles.paragraph}>
        As part of the registration and matchmaking process on our platform, we collect personal information such as your name, contact details, ethnicity, religion, and other relevant data. This information helps us in providing you with a personalized experience and matching you with potential partners based on your preferences.
      </p>

      <h2 style={styles.subtitle}>Data Sharing with Confluence Ventures (Pvt) Ltd</h2>
      <p style={styles.paragraph}>
        Please note that your data may be shared with other online services operated by Confluence Ventures (Pvt) Ltd. We ensure that this data is shared responsibly and in accordance with applicable privacy laws to enhance your experience across our services. For a full list of our services, please visit 
        <a href="https://www.cventures.lk" target="_blank" rel="noopener noreferrer" style={styles.link}> Confluence Ventures (Pvt) Ltd</a>.
      </p>

      <h2 style={styles.subtitle}>Serving Sri Lankan Nationalities</h2>
      <p style={styles.paragraph}>
        Our matrimonial portal is designed to serve Sri Lankan nationalities, including Tamils, Indian Tamils, Sinhalese, and other communities. We are committed to providing a platform where people from diverse cultural backgrounds can connect in a respectful and secure environment.
      </p>

      <h2 style={styles.subtitle}>Your Consent</h2>
      <p style={styles.paragraph}>
        By using our services, you consent to the collection, use, and sharing of your personal data as outlined in this Privacy Policy. If you do not agree with any part of this policy, we advise you to discontinue using our services.
      </p>

      <h2 style={styles.subtitle}>Your Rights</h2>
      <p style={styles.paragraph}>
        We respect your rights regarding your personal data. You have the right to request access to, correction, or deletion of your data. If you have any questions or wish to exercise your rights, please contact us through the available channels.
      </p>

      <h2 style={styles.subtitle}>Security Measures</h2>
      <p style={styles.paragraph}>
        We take security seriously and have implemented appropriate technical and organizational measures to protect your data from unauthorized access, loss, or misuse. However, please be aware that no system is completely secure, and we cannot guarantee the absolute security of your data.
      </p>

      <h2 style={styles.subtitle}>Changes to this Policy</h2>
      <p style={styles.paragraph}>
        This Privacy Policy may be updated from time to time to reflect changes in our services or applicable laws. We encourage you to review this policy periodically for any updates.
      </p>

      <p style={styles.signature}>
        Thank you for trusting us. We are dedicated to protecting your privacy and ensuring that your experience on our platform is safe and enjoyable.
      </p>

      <p style={styles.signature}>
        Sincerely,<br />
        The Team at Confluence Ventures (Pvt) Ltd
      </p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f9f9f9',
    padding: '40px',
    borderRadius: '10px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '900px',
    margin: '40px auto',
    color: '#333',
  },
  title: {
    color: '#007bff',
    textAlign: 'center',
    marginBottom: '20px',
  },
  subtitle: {
    color: '#333',
    marginBottom: '10px',
    fontSize: '18px',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  signature: {
    fontSize: '16px',
    marginTop: '40px',
    fontStyle: 'italic',
    textAlign: 'center',
  },
};

export default PrivacyPolicy;
