import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { API_IDENTITY_URL } from '../lib/constants';
import { API_IDENTITY_URL } from '../common/AppControl';
import { useAuth } from './profile/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  fetchTitleData,
  fetchCountryData,
} from '../User/Service/ProfileCx';

const UpdateUserForm = () => {
  const { token } = useAuth(); // Fetch the token
  const [formData, setFormData] = useState({
    title: '',
    customerName: '',
    eMail: '', // Added email field
    organization: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    phone1: '',
    phone2: '',
    fax: '',
    mobile: '',
    countryId: '',
    countryName: '',
  });

  const [salutations, setSalutations] = useState([]); // State for salutation options
  const [countries, setCountries] = useState([]); // State for country options
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user details, salutations, and countries from the JSON files
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userUrl = `${API_IDENTITY_URL}getuserdetails`;
        const response = await axios.get(userUrl, {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token
          },
        });

        // Ensure countryId and countryName are properly set
        const userData = response.data;
        if (!userData.countryId) userData.countryId = '';
        if (!userData.countryName) userData.countryName = '';

        setFormData(userData);
        setLoading(false);
      } catch (err) {
        setError('Failed to load user details.');
        setLoading(false);
      }
    };

    const fetchSalutations = async () => {
      try {
        const data = await fetchTitleData();
        setSalutations(data);
      } catch (error) {
        console.error('Failed to load titles:', error);
      }
    };

    const fetchCountries = async () => {
      try {
        const data = await fetchCountryData();
        setCountries(data);
      } catch (error) {
        console.error('Failed to load titles:', error);
      }
    };


    fetchUserDetails();
    fetchSalutations();
    fetchCountries();
  }, [token]);

  // Update countryName when countryId or countries change
  useEffect(() => {
    if (formData.countryId && countries.length > 0) {
      const selectedCountry = countries.find(
        (country) => country.value.toString() === formData.countryId.toString()
      );
      if (selectedCountry) {
        setFormData((prevData) => ({
          ...prevData,
          countryName: selectedCountry.text,
        }));
      }
    }
  }, [formData.countryId, countries]);


  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (name === 'countryId') {
      // Find the selected country object
      const selectedCountry = countries.find(
        (country) => country.value.toString() === value.toString()
      );
      if (selectedCountry) {
        updatedFormData.countryName = selectedCountry.text;
      } else {
        updatedFormData.countryName = '';
      }
    }

    setFormData(updatedFormData);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_IDENTITY_URL}updateuser`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Bearer token
        },
      });
      toast.success('User updated successfully!'); // Use toast for success message
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user.'); // Use toast for error message
    }
  };

  if (loading) {
    return <p>Loading user details...</p>;
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <ToastContainer /> {/* Include ToastContainer */}
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
        {/* Title and Full Name */}
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div style={{ marginRight: '10px' }}>
            <label htmlFor="title">Title:</label><br />
            <select
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              style={{ width: '100px', padding: '5px' }}
            >
              {salutations.map((salutation, index) => (
                <option key={index} value={salutation.value}>
                  {salutation.text}
                </option>
              ))}
            </select>
 
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="customerName">Full Name:</label><br />
            <input
              type="text"
              id="customerName"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
        </div>

        {/* Email (Read-only) */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="eMail">Email:</label><br />
          <input
            type="email"
            id="email"
            name="email"
            value={formData.eMail}
            readOnly
            style={{ width: '100%', padding: '5px', backgroundColor: '#f0f0f0' }}
          />
        </div>

        {/* Organization */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="organization">Organization:</label><br />
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>

        {/* Address 1 */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="address1">Address 1:</label><br />
          <input
            type="text"
            id="address1"
            name="address1"
            value={formData.address1}
            onChange={handleChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>

        {/* Address 2 */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="address2">Address 2:</label><br />
          <input
            type="text"
            id="address2"
            name="address2"
            value={formData.address2}
            onChange={handleChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>

        {/* City and Postcode */}
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <label htmlFor="city">City:</label><br />
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ width: '150px' }}>
            <label htmlFor="postcode">Postcode:</label><br />
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
        </div>

        {/* Phone 1 and Phone 2 */}
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <label htmlFor="phone1">Phone 1:</label><br />
            <input
              type="text"
              id="phone1"
              name="phone1"
              value={formData.phone1}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="phone2">Phone 2:</label><br />
            <input
              type="text"
              id="phone2"
              name="phone2"
              value={formData.phone2}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
        </div>

        {/* Fax and Mobile */}
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <label htmlFor="fax">Fax:</label><br />
            <input
              type="text"
              id="fax"
              name="fax"
              value={formData.fax}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="mobile">Mobile:</label><br />
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              style={{ width: '100%', padding: '5px' }}
            />
          </div>
        </div>

        {/* Country Dropdown */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="countryId">Country:</label><br />
          <select
            id="countryId"
            name="countryId"
            value={formData.countryId}
            onChange={handleChange}
            style={{ width: '100%', padding: '5px' }}
          >
            {countries.map((country) => (
              <option key={country.value} value={country.value}>
                {country.text}
              </option>
            ))}
          </select>
        </div>

        {/* Country Name (Read-only) */}
  <input
            type="hidden"
            id="countryName"
            name="countryName"
            value={formData.countryName}
            readOnly
          />


        <button type="submit" style={{ padding: '10px 20px' }}>Update User</button>
      </form>

      <ToastContainer /> {/* Include ToastContainer */}
    </div>
  );
};

export default UpdateUserForm;
