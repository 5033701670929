import React from 'react';

const faqs = [
  {
    question: "How can I sign up?",
    answer: "To sign up, click on the 'Login/Register' button on our homepage. A pop-up will appear. Choose the 'Register' tab and fill in your name, email, and password. Then click 'Submit' to create your account."
  },
  {
    question: "How can I log in?",
    answer: "Click 'Login/Register' on the homepage, select the 'Login' tab in the pop-up, enter your email and password, and click 'Submit' to log in."
  },
  {
    question: "How can I add a profile for a bride or groom?",
    answer: "After logging in, go to 'Profiles' and click 'Add Profile.' Choose 'Bride' or 'Groom,' fill in the details, and click 'Save' to create the profile."
  },
  {
    question: "How can I reset my password?",
    answer: "Click 'Login/Register' on the homepage, then select 'Reset Password.' Enter your email and click 'Submit.' We'll send you a code by email. Enter the code, create a new password, and click 'Submit' to save it."
  },
  {
    question: "How do I search for profiles?",
    answer: "Go to 'Search Profiles' in the menu. Use filters like age, location, and interests. After setting your filters, click 'Search' to see matching profiles."
  },
  {
    question: "How do I update my account info?",
    answer: "Log in and go to 'My Profile.' Click 'Update Details,' make your changes, and then click 'Update User' to save them."
  },
  {
    question: "How can I edit a bride or groom profile?",
    answer: "Log in and go to 'My Profiles.' Select the profile you want to edit, click 'Edit,' make your changes, and click 'Save.'\n\n**Note:** You can't change the gender or date of birth. If you need to, you can delete the profile and make a new one, or contact us for help."
  },
  {
    question: "How can I delete my profile?",
    answer: "Log in, go to 'My Profiles,' select the profile you want to delete, click 'Delete,' and follow the steps to confirm. **Note:** This can't be undone."
  },
  {
    question: "How do I change my password?",
    answer: "Log in, go to 'My Profile,' click 'Change Password,' enter your current password, then your new password twice, and click 'Change Password' to save it."
  },
  {
    question: "Do I have to pay?",
    answer: "No, our matrimonial services are free. We charge for extra services like translation or astrology. Contact us if you're interested."
  },
  {
    question: "Why can't I see all the details like contact info, photos, or astrology data?",
    answer: "You need to be logged in with an approved profile of the opposite gender. Some users choose to protect certain details. In that case, you can request access. Once they approve, you both can see the protected info."
  },
  {
    question: "How many profiles can I have?",
    answer: "You can have up to 3 profiles. If you need more, contact us with the reason. We may charge depending on your needs."
  },
  {
    question: "Can I meet someone from your team in person?",
    answer: "Sorry, we can't meet in person or take calls. We're a small team in Jaffna with a tight budget. Please send us a message, and we'll reply within 2-3 working days."
  },
  {
    question: "If you don't charge, how do you keep the site running?",
    answer: "This website showcases our technical skills to attract projects for our livelihood. Also, we have a grant from Microsoft to use their services."
  },
];

const FAQPage = () => {
  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h2>{faq.question}</h2>
          <p>{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default FAQPage;
