import React from 'react';
import ProfileShortcuts from './ProfileShortcuts';
import RandomProfiles from './RandomProfiles';
function Home() {
  return (
    <>
    <ProfileShortcuts />
  
    <RandomProfiles />
    </>
  );
}

export default Home;
