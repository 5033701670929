// RandomProfiles.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import './RandomProfiles.css'; // Import custom CSS if needed
//import { API_URL } from '../common/config';
import { API_URL } from '../common/AppControl';
const RandomProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

 // const API_URL = 'pp/random'; // Replace with your actual API base URL if needed

  useEffect(() => {
    const fetchRandomProfiles = async () => {
      try {
        const response = await axios.get(`${API_URL}pp/random`);
        const items = response.data.items;

        // Parse the searchResults JSON strings
        const parsedProfiles = items.map((item) => JSON.parse(item.searchResults));

        setProfiles(parsedProfiles);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchRandomProfiles();
  }, []);

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="my-5">
        Error fetching random profiles.
      </Alert>
    );
  }

  return (
    <div className="container my-5">
      <Row>
        {profiles.map((profile) => (
          <Col key={profile.profileid} md={4} className="mb-4">
            <Card
              className={`h-100 profile-card ${
                profile.sex === 'Male' ? 'male-profile' : 'female-profile'
              }`}
            >
              <Card.Body>
                <Card.Title className="text-center">
                  {profile.name}
                </Card.Title>
                <div className="profile-image-container">
                  <Card.Img
                    variant="top"
                    src={profile.defaultimage}
                    alt={`${profile.name}'s profile`}
                    className="mb-3 profile-image"
                  />
                </div>
                <Card.Text>
                  <strong>Age:</strong> {profile.age}
                  <br />
                  <strong>City:</strong> {profile.livingcity || 'Not provided'}
                  <br />
                  <strong>Ethnicity:</strong> {profile.ethnicity}
                  <br />
                  <strong>Religion:</strong> {profile.religion}
                  <br />
                  <strong>Marital Status:</strong> {profile.martialstatus}
                </Card.Text>
                <a href={`/profile/${profile.profileURL}`}  className="btn btn-primary btn-block">
                  View Profile
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default RandomProfiles;
